<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            ESIC Challan
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>

                  <div class="col-md-4">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showEsicChallanTable"></div>
                <section id="mydiv" v-if="showEsicChallan">
                  <div v-if="esic_calculation.users.length">
                    <div class="container-fluid">
                      <div class="col-md-6">
                        <center><p>E.S.I.C</p></center>
                        <p style="float: right">Challan No. 1</p>
                        <br />
                        <p style="font-weight: bold">
                          <center>
                            EMPLOYEE'S STATE INSURANCE FUND ACCOUNT NO - 1
                          </center>
                        </p>
                        <p style="font-weight: bold">
                          <center>PAY-IN-SLIP FOR CONTIBUTION</center>
                        </p>
                        <p style="font-weight: bold">
                          <center>STATE BANK OF INDIA</center>
                        </p>
                        <div class="col-md-3">
                          <p style="font-weight: bold">Station</p>
                        </div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3">Date:</div>
                        <div class="col-md-3">
                          {{
                            new Date().toJSON().slice(0, 10).replace(/-/g, "/")
                          }}
                        </div>
                        <div class="col-md-12"></div>
                        <div class="col-md-6">
                          <table
                            id="table-example-1"
                            class="table table-responsive col-md-10"
                          >
                            <thead>
                              <tr>
                                <th rowspan="2">
                                  Particulars of Cash / Cheque
                                </th>
                                <th>Amount</th>
                              </tr>
                              <tr>
                                <th>Rs. P.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>{{ esic_calculation.total_esic }}.00</td>
                              </tr>
                              <tr>
                                <th>TOTAL</th>
                                <th>{{ esic_calculation.total_esic }}.00</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-6">
                          <p>Paid into the credit of the Employee's State</p>
                          <p>Insurance Fund</p>
                          <p>
                            Accound No. 1 Rs.
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_esic }}.00.00</span
                            >
                          </p>
                          <p>
                            (Rupee
                            {{ esic_calculation.total_esic_in_words }} Only)
                          </p>
                          <br />
                          <br />
                          <p>In Cash/by Cheque (on reallisation) for payment</p>
                          <p>of contribution as per details given below</p>
                          <p>under the Employee State Insurance Act 1948.</p>
                          <p>under the month of {{ month_name }} {{ year }}</p>
                        </div>
                        <div class="col-md-12">
                          <p>Emplyer's Code No. :</p>
                          <p>Deposited bt:</p>
                        </div>
                        <div class="col-md-12">
                          <hr />
                          <center>
                            <p>
                              Name And Address of the Factory / Establishment
                            </p>
                          </center>
                          <p style="font-size: 20px; font-weight: bold">
                            {{ esic_calculation.site_name }}
                          </p>
                          <!-- <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
                        <p>CBD Belapur, Navi Mumbai-4000614.</p>
                        <p>Plot no-61</p>
                        <p>Navi Mumbai-4000614.</p> -->
                          <p>{{ esic_calculation.site_address }}</p>
                          <hr />
                        </div>
                        <div class="col-md-12"></div>
                        <div class="col-md-6">
                          <p>
                            No. Of Employees :
                            <span style="font-weight: bold">{{
                              esic_calculation.total_users
                            }}</span>
                          </p>
                          <p>
                            Employee's Contribution Rs:
                            <span style="font-weight: bold"
                              >{{
                                esic_calculation.total_employee_esic
                              }}.00</span
                            >
                          </p>
                          <p>
                            Employer's Contribution Rs:
                            <span style="font-weight: bold"
                              >{{
                                esic_calculation.total_employer_esic
                              }}.00</span
                            >
                          </p>
                          <p>
                            Total Rs:
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_esic }}.00</span
                            >
                          </p>
                        </div>
                        <div class="col-md-6">
                          <p>
                            Total Wages:
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_wages }}.00</span
                            >
                          </p>
                        </div>
                        <div class="col-md-12">
                          <hr />
                          <p>(For use in bank)</p>
                          <center>
                            <p style="font-weight: bold; font-size: 15px">
                              ACKNOWLEDGEMENT
                            </p>
                          </center>
                          <center><p>(To be filled by depositor)</p></center>
                          <p>Received payment with Cash / Cheque / Draft No.</p>
                          <p>
                            Date
                            {{
                              new Date()
                                .toJSON()
                                .slice(0, 10)
                                .replace(/-/g, "/")
                            }}
                            for Rs .
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_esic }}.00</span
                            >
                          </p>
                          <p>
                            (Rupee
                            {{ esic_calculation.total_esic_in_words }} Only)
                          </p>
                          <p>draft on ICICI BANK (Bank) in favour of</p>
                          <p>Employee's Sate Insurance Fund Account No 1.</p>
                          <p>SI No. In Bank Scroll</p>
                        </div>

                        <div class="col-md-6">
                          <p>
                            Date :
                            {{
                              new Date()
                                .toJSON()
                                .slice(0, 10)
                                .replace(/-/g, "/")
                            }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <p>Authrised Signatory</p>
                        </div>
                      </div>

                      <!-- Second half -->
                      <div class="col-md-6">
                        <center><p>E.S.I.C</p></center>
                        <p style="float: right">Challan No. 1</p>
                        <br />
                        <p style="font-weight: bold">
                          <center>
                            EMPLOYEE'S STATE INSURANCE FUND ACCOUNT NO - 1
                          </center>
                        </p>
                        <p style="font-weight: bold">
                          <center>PAY-IN-SLIP FOR CONTIBUTION</center>
                        </p>
                        <p style="font-weight: bold">
                          <center>STATE BANK OF INDIA</center>
                        </p>
                        <div class="col-md-3">
                          <p style="font-weight: bold">Station</p>
                        </div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3">Date:</div>
                        <div class="col-md-3">
                          {{
                            new Date().toJSON().slice(0, 10).replace(/-/g, "/")
                          }}
                        </div>
                        <div class="col-md-12"></div>
                        <div class="col-md-6">
                          <table
                            id="table-example-1"
                            class="table table-responsive col-md-10"
                          >
                            <thead>
                              <tr>
                                <th rowspan="2">
                                  Particulars of Cash / Cheque
                                </th>
                                <th>Amount</th>
                              </tr>
                              <tr>
                                <th>Rs. P.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>{{ esic_calculation.total_esic }}.00</td>
                              </tr>
                              <tr>
                                <th>TOTAL</th>
                                <th>{{ esic_calculation.total_esic }}.00</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-6">
                          <p>Paid into the credit of the Employee's State</p>
                          <p>Insurance Fund</p>
                          <p>
                            Accound No. 1 Rs.
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_esic }}.00</span
                            >
                          </p>
                          <p>
                            (Rupee
                            {{ esic_calculation.total_esic_in_words }} Only)
                          </p>
                          <br />
                          <br />
                          <p>In Cash/by Cheque (on reallisation) for payment</p>
                          <p>of contribution as per details given below</p>
                          <p>under the Employee State Insurance Act 1948.</p>
                          <p>under the month of {{ month_name }} {{ year }}</p>
                        </div>
                        <div class="col-md-12">
                          <p>Emplyer's Code No. :</p>
                          <p>Deposited bt:</p>
                        </div>
                        <div class="col-md-12">
                          <hr />
                          <center>
                            <p>
                              Name And Address of the Factory / Establishment
                            </p>
                          </center>
                          <p style="font-size: 20px; font-weight: bold">
                            {{ esic_calculation.site_name }}
                          </p>
                          <!-- <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
                        <p>CBD Belapur, Navi Mumbai-4000614.</p>
                        <p>Plot no-61</p>
                        <p>Navi Mumbai-4000614.</p> -->
                          <p>{{ esic_calculation.site_address }}</p>
                          <hr />
                        </div>
                        <div class="col-md-12"></div>
                        <div class="col-md-6">
                          <p>
                            No. Of Employees :
                            <span style="font-weight: bold">{{
                              esic_calculation.total_users
                            }}</span>
                          </p>
                          <p>
                            Employee's Contribution Rs:
                            <span style="font-weight: bold"
                              >{{
                                esic_calculation.total_employee_esic
                              }}.00</span
                            >
                          </p>
                          <p>
                            Employer's Contribution Rs:
                            <span style="font-weight: bold"
                              >{{
                                esic_calculation.total_employer_esic
                              }}.00</span
                            >
                          </p>
                          <p>
                            Total Rs:
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_esic }}.00</span
                            >
                          </p>
                        </div>
                        <div class="col-md-6">
                          <p>
                            Total Wages:
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_wages }}.00</span
                            >
                          </p>
                        </div>
                        <div class="col-md-12">
                          <hr />
                          <p>(For use in bank)</p>
                          <center>
                            <p style="font-weight: bold; font-size: 15px">
                              ACKNOWLEDGEMENT
                            </p>
                          </center>
                          <center><p>(To be filled by depositor)</p></center>
                          <p>Received payment with Cash / Cheque / Draft No.</p>
                          <p>
                            Date
                            {{
                              new Date()
                                .toJSON()
                                .slice(0, 10)
                                .replace(/-/g, "/")
                            }}
                            for Rs .
                            <span style="font-weight: bold"
                              >{{ esic_calculation.total_esic }}.00</span
                            >
                          </p>
                          <p>
                            (Rupee
                            {{ esic_calculation.total_esic_in_words }} Only)
                          </p>
                          <p>draft on ICICI BANK (Bank) in favour of</p>
                          <p>Employee's Sate Insurance Fund Account No 1.</p>
                          <p>SI No. In Bank Scroll</p>
                        </div>

                        <div class="col-md-6">
                          <p>
                            Date :{{
                              new Date()
                                .toJSON()
                                .slice(0, 10)
                                .replace(/-/g, "/")
                            }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <p>Authrised Signatory</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Challan Not yet Generated</h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFChallan",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showEsicChallanTable: false,
      showEsicChallan: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showEsicChallan = false;
      this.showEsicChallanTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let esic_calculation = await axios.get(
          `/esic_register/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.esic_calculation = esic_calculation.data.data;
        console.log(esic_calculation);
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showEsicChallanTable = true;
        this.showEsicChallan = false;
      }
      if (source == "generate") {
        this.showEsicChallanTable = false;
        this.showEsicChallan = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "ESICChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
