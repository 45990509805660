<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            ESIC Register
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-4">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print</button
                    >&nbsp; &nbsp;
                    <download-excel
                      :title="title"
                      class="btn btn-default"
                      :data="esic_excel"
                      :fields="excel_fields"
                      worksheet="Esic"
                      name="ESICRegister.xls"
                    >
                      <a href="#" class="download">Export to Excel</a>
                    </download-excel>

                    <small v-if="isSaving">Saving...</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showRegisterTable"></div>
                <section id="mydiv" v-if="showRegister">
                  <div v-if="esicCalculations.length">
                    <div class="container-fluid" style="margin-top: 20px">
                      <p style="font-size: 18px; font-weight: bold">
                        ESIC REGISTER : FOR THE MONTH: {{ month_name }}
                        {{ year }}
                      </p>
                      <div class="table-wrap">
                        <div class="inner" style="height: 700px">
                          <table class="table table-responsive col-md-12">
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Employee Code</th>
                                <th>Division Name</th>
                                <th>Employee Name</th>
                                <th>Father Name</th>
                                <th>Designation Name</th>
                                <th>Merital Status</th>
                                <th>Date Of Birth</th>
                                <th>Date Of Joining</th>
                                <th>ESIC Number</th>
                                <th>Absent Days</th>
                                <th>Days Presnt</th>
                                <th>Days Paid</th>
                                <th>ESIC Wages</th>
                                <th>E.S.I.C</th>
                                <th>E.S.I.C Cont.</th>
                                <th>Total Esic</th>
                                <th>State</th>
                                <th>Branch</th>
                              </tr>
                            </thead>
                            <tbody>
                              <loading-table
                                :isLoading="isLoading"
                                cols="11"
                              ></loading-table>
                              <tr
                                v-for="(user, at) in esicCalculations"
                                :key="`user${at}`"
                              >
                                <td>{{ serialNoStarting + at + 1 }}</td>
                                <td>{{ user.employee_id }}</td>
                                <td>{{ user.group_division.division_name }}</td>
                                <td>{{ user.full_name }}</td>
                                <td>{{ user.father_name }}</td>
                                <td>
                                  {{
                                    user.designation_category
                                      ? user.designation_category.category_list
                                          .code
                                      : ""
                                  }}
                                </td>
                                <td>
                                  {{
                                    user.marital_status
                                      ? user.marital_status.code
                                      : ""
                                  }}
                                </td>
                                <td>{{ user.dob }}</td>
                                <td>{{ user.joined_on }}</td>
                                <td>{{ user.esi_number }}</td>
                                <td>{{ user.monthly_salary.days_absent }}</td>
                                <td>{{ user.monthly_salary.days_present }}</td>
                                <td>{{ user.monthly_salary.payable_days }}</td>
                                <td>
                                  {{ user.monthly_salary.earned_esic_wages }}
                                </td>
                                <td>{{ user.monthly_salary.employee_esic }}</td>
                                <td>{{ user.employer_esic }}</td>
                                <td>{{ user.total_esic }}</td>
                                <td>{{ user.group_division.state.code }}</td>
                                <td>
                                  {{
                                    user.branch_category
                                      ? user.branch_category.category_list.code
                                      : ""
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Register Not Yet Generated</h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFRegister",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      esicCalculations: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showRegisterTable: false,
      showRegister: false,
      serialNoStarting: 0,
      excel_fields: {
        "Sr No": "sr_no",
        "Employee Code": "employee_code",
        "Division Name": "division_name",
        Title: "title",
        "Employee Name": "employee_name",
        "Father's Name": "father_name",
        Designation: "designation_name",
        "Marital Status": "marital_status",
        "Date of Birth": "dob",
        "Date Joined": "joined_on",
        "ESIC Number": "esi_number",
        "Absent Days": "days_absent",
        "Days Present": "days_present",
        "Days Paid": "payable_days",
        "ESIC Wages": "earned_esic_wages",
        ESIC: "employee_esic",
        "ESIC Cont": "employer_esic",
        "Total ESIC": "total_esic",
        State: "state",
        Branch: "branch",
      },
      esic_excel: [],
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showRegister = false;
      this.showRegisterTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let esicCalculations = await axios.get(
          `/esic_register/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.esicCalculations = esicCalculations.data.data.users;
        console.log(this.esicCalculations);
        // let users = await axios.get(`/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`)
        // this.users = [];
        let designation_category = {};
        let branch_category = {};
        this.esicCalculations.forEach((user) => {
          // Designation

          let designation_categories = user.user_categories.filter(
            (user_category) =>
              user_category.category.category_name == "DESIGNATION"
          );
          if (designation_categories.length > 0)
            designation_category = designation_categories[0];
          user.designation_category = designation_category;

          // Branch

          let branch_categories = user.user_categories.filter(
            (user_category) => user_category.category.category_name == "BRANCH"
          );
          if (branch_categories.length > 0)
            branch_category = branch_categories[0];
          user.branch_category = branch_category;
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showRegisterTable = true;
        this.showRegister = false;
      }
      if (source == "generate") {
        this.showRegisterTable = false;
        this.showRegister = true;
      }
      this.exportToExcel();
    },
    exportToExcel() {
      this.title =
        "ESIC Register For the Month of " + this.month_name + " " + this.year;

      this.esic_excel = [];
      let sr_no = 1;
      // console.log(this.esic);
      this.esicCalculations.forEach((employee) => {
        // Branch

        let branch_category = {};
        let branch_categories = employee.user_categories.filter(
          (user_category) => user_category.category.category_name == "BRANCH"
        );
        if (branch_categories.length > 0)
          branch_category = branch_categories[0];
        // Grade
        // let grade_category = {};
        // let grade_categories = employee.user_categories.filter(
        //   (user_category) => user_category.category.category_name == "GRADE"
        // );
        // if (grade_categories.length > 0) grade_category = grade_categories[0];
        // // Department
        // let department_category = {};
        // let department_categories = employee.user_categories.filter(
        //   (user_category) =>
        //     user_category.category.category_name == "DEPARTMENT"
        // );
        // if (department_categories.length > 0)
        //   department_category = department_categories[0];
        // // Category
        // let category_category = {};
        // let category_categories = employee.user_categories.filter(
        //   (user_category) =>
        //     user_category.category.category_name == '"CATEGORY"'
        // );
        // if (category_categories.length > 0)
        //   category_category = category_categories[0];
        // Designation
        let designation_category = {};
        let designation_categories = employee.user_categories.filter(
          (user_category) =>
            user_category.category.category_name == "DESIGNATION"
        );
        if (designation_categories.length > 0)
          designation_category = designation_categories[0];
        this.esic_excel.push({
          sr_no: sr_no,
          employee_code: employee.employee_id,
          division_name: employee.group_division
            ? employee.group_division.division_name
            : "",
          title: employee.title,
          employee_name: employee.full_name,
          father_name: employee.father_name ? employee.father_name : "",
          designation_name: designation_category.category_list
            ? designation_category.category_list.code
            : "",
          marital_status: employee.marital_status.code,
          dob: employee.dob ? employee.dob : "",
          joined_on: employee.joined_on ? employee.joined_on : "",
          esi_number: employee.esi_number,
          state: employee.group_division
            ? employee.group_division.state.code
            : "",
          branch: branch_category.category_list
            ? branch_category.category_list.code
            : "",
          days_absent: employee.monthly_salary.days_absent,
          days_present: employee.monthly_salary.days_present,
          payable_days: employee.monthly_salary.payable_days,
          earned_esic_wages: employee.monthly_salary.earned_esic_wages,
          employee_esic: employee.monthly_salary.employee_esic,
          employer_esic: employee.employer_esic,
          total_esic: employee.total_esic,
        });
        sr_no++;
      });
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "EsicRegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
