<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            PT Challan
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-datepicker
                      :date.sync="from_date"
                      placeholder="From Date"
                    ></my-datepicker>
                  </div>
                  <div class="col-md-3">
                    <my-datepicker
                      :date.sync="to_date"
                      placeholder="To Date"
                    ></my-datepicker>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="state_id"
                      :suggestions="states"
                      :placeholder="`Select State`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showPTChallanTable"></div>
                <section id="mydiv" v-if="showPTChallan">
                  <div v-if="pt_calculation.users.length">
                    <div class="container-fluid">
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-4"><p>PART-I</p></div>
                          <div class="col-md-4">
                            <p><center>Challan</center></p>
                          </div>
                          <div class="col-md-4">
                            <p style="float: right">FOR TREASURY</p>
                          </div>
                        </div>
                        <br />
                        <p style="font-weight: bold">
                          <center>MTR-6</center>
                        </p>
                        <br />
                        <p>
                          <center>
                            (see Rule 11, 11C, 11E and 20 of the professions Tax
                            Rules, 1975)
                          </center>
                          <br />
                          <center>ACCOUNT HEAD:- 00280012</center>
                        </p>
                        <br />

                        <div class="row">
                          <table
                            id="table-example-1"
                            class="table table-responsive col-md-10"
                          >
                            <thead>
                              <tr>
                                <th colspan="2">GRN</th>
                                <th>Form-ID</th>
                                <th>
                                  <ol>
                                    <li>I</li>
                                    <li>IIIB</li>
                                    <li>VIII</li>
                                  </ol>
                                </th>
                              </tr>
                              <tr>
                                <th>Department</th>
                                <th>Department of Sales Tax</th>
                                <th>Date:-</th>
                                <th>01/06/2021</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td rowspan="2">Type of Payment</td>
                                <td style="font-weight: bold">
                                  Professions Tax Act. 1975
                                </td>
                                <td colspan="2">Payee Details</td>
                              </tr>
                              <tr>
                                <td>
                                  <ol>
                                    <li>RC</li>
                                    <li>EC</li>
                                  </ol>
                                  ( Select the applicable one )
                                </td>
                                <td colspan="2">
                                  <p>PT( RC/EC )</p>
                                  <p>Number</p>
                                  <p>(Tin)</p>
                                  <p>27801522796P</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Location MUMBAI</td>
                                <td rowspan="3" colspan="2">
                                  <p>Full Name of the Tax Payer</p>
                                  <p style="font-weight: bold">
                                    {{ pt_calculation.site_name }}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td style="font-weight: bold" colspan="2">
                                  <center>Period</center>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p style="font-weight: bold">FROM</p>
                                  <p>{{ pt_calculation.from_date }}</p>
                                </td>
                                <td>
                                  <p style="font-weight: bold">TO</p>
                                  <p>{{ pt_calculation.to_date }}</p>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th>Account Head Details</th>
                                <th>Code</th>
                                <th>Amount in Rs,</th>
                                <th>Remarks if Any,</th>
                              </tr>
                              <tr>
                                <td>Amount of Tax</td>
                                <td>1</td>
                                <td>{{ pt_calculation.total_pt }}</td>
                                <td rowspan="8"></td>
                              </tr>
                              <tr>
                                <td>Interest Amount</td>
                                <td>2</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Penality Amount</td>
                                <td>3</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Composition Money</td>
                                <td>4</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Fine</td>
                                <td>5</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Fees</td>
                                <td>6</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Advances Payment</td>
                                <td>7</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td style="font-weight: bold">Total</td>
                                <td></td>
                                <td>{{ pt_calculation.total_pt }}</td>
                              </tr>
                              <tr>
                                <td colspan="5" rowspan="5">
                                  <p style="float: left">
                                    Amount in Words:
                                    {{ pt_calculation.total_pt_words }}
                                  </p>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <p style="float: right">
                                    Signature of Person has made payment
                                  </p>
                                </td>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <td colspan="4">
                                  <center>FOR BANKS/ TREASURY</center>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Payment Details</td>
                                <td colspan="2">For use in receiving Bank</td>
                              </tr>
                              <tr>
                                <td>Name of bank</td>
                                <td></td>
                                <td>Bank CIN</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name of Branch</td>
                                <td></td>
                                <td>Date</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Time</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Scroll No.</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- Second half -->
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-4"><p>PART-II</p></div>
                          <div class="col-md-6">
                            <p><center>Challan</center></p>
                            <p style="float: right; font-size: 9px !important">
                              FOR PROFESSION TAX OFFICER
                            </p>
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                        <br />
                        <p style="font-weight: bold">
                          <center>MTR-6</center>
                        </p>
                        <br />
                        <p>
                          <center>
                            (see Rule 11, 11C, 11E and 20 of the professions Tax
                            Rules, 1975)
                          </center>
                          <br />
                          <center>ACCOUNT HEAD:- 00280012</center>
                        </p>
                        <br />

                        <div class="row">
                          <table
                            id="table-example-1"
                            class="table table-responsive col-md-10"
                          >
                            <thead>
                              <tr>
                                <th colspan="2">GRN</th>
                                <th>Form-ID</th>
                                <th>
                                  <ol>
                                    <li>I</li>
                                    <li>IIIB</li>
                                    <li>VIII</li>
                                  </ol>
                                </th>
                              </tr>
                              <tr>
                                <th>Department</th>
                                <th>Department of Sales Tax</th>
                                <th>Date:-</th>
                                <th>01/06/2021</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td rowspan="2">Type of Payment</td>
                                <td style="font-weight: bold">
                                  Professions Tax Act. 1975
                                </td>
                                <td colspan="2">Payee Details</td>
                              </tr>
                              <tr>
                                <td>
                                  <ol>
                                    <li>RC</li>
                                    <li>EC</li>
                                  </ol>
                                  ( Select the applicable one )
                                </td>
                                <td colspan="2">
                                  <p>PT( RC/EC )</p>
                                  <p>Number</p>
                                  <p>(Tin)</p>
                                  <p>27801522796P</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Location MUMBAI</td>
                                <td rowspan="3" colspan="2">
                                  <p>Full Name of the Tax Payer</p>
                                  <p style="font-weight: bold">
                                    {{ pt_calculation.site_name }}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td style="font-weight: bold" colspan="2">
                                  <center>Period</center>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p style="font-weight: bold">FROM</p>
                                  <p>{{ pt_calculation.from_date }}</p>
                                </td>
                                <td>
                                  <p style="font-weight: bold">TO</p>
                                  <p>{{ pt_calculation.to_date }}</p>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th>Account Head Details</th>
                                <th>Code</th>
                                <th>Amount in Rs,</th>
                                <th>Remarks if Any,</th>
                              </tr>
                              <tr>
                                <td>Amount of Tax</td>
                                <td>1</td>
                                <td>{{ pt_calculation.total_pt }}</td>
                                <td rowspan="8"></td>
                              </tr>
                              <tr>
                                <td>Interest Amount</td>
                                <td>2</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Penality Amount</td>
                                <td>3</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Composition Money</td>
                                <td>4</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Fine</td>
                                <td>5</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Fees</td>
                                <td>6</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Advances Payment</td>
                                <td>7</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td style="font-weight: bold">Total</td>
                                <td></td>
                                <td>{{ pt_calculation.total_pt }}</td>
                              </tr>
                              <tr>
                                <td colspan="5" rowspan="5">
                                  <p style="float: left">
                                    Amount in Words:
                                    {{ pt_calculation.total_pt_words }}
                                  </p>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <p style="float: right">
                                    Signature of Person has made payment
                                  </p>
                                </td>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <td colspan="4">
                                  <center>FOR BANKS/ TREASURY</center>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Payment Details</td>
                                <td colspan="2">For use in receiving Bank</td>
                              </tr>
                              <tr>
                                <td>Name of bank</td>
                                <td></td>
                                <td>Bank CIN</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name of Branch</td>
                                <td></td>
                                <td>Date</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Time</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Scroll No.</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- Third half -->
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-4"><p>PART-III</p></div>
                          <div class="col-md-4">
                            <p><center>Challan</center></p>
                          </div>
                          <div class="col-md-4">
                            <p style="float: right">FOR TAX PAYER</p>
                          </div>
                        </div>
                        <br />
                        <p style="font-weight: bold">
                          <center>MTR-6</center>
                        </p>
                        <br />
                        <p>
                          <center>
                            (see Rule 11, 11C, 11E and 20 of the professions Tax
                            Rules, 1975)
                          </center>
                          <br />
                          <center>ACCOUNT HEAD:- 00280012</center>
                        </p>
                        <br />

                        <div class="row">
                          <table
                            id="table-example-1"
                            class="table table-responsive col-md-10"
                          >
                            <thead>
                              <tr>
                                <th colspan="2">GRN</th>
                                <th>Form-ID</th>
                                <th>
                                  <ol>
                                    <li>I</li>
                                    <li>IIIB</li>
                                    <li>VIII</li>
                                  </ol>
                                </th>
                              </tr>
                              <tr>
                                <th>Department</th>
                                <th>Department of Sales Tax</th>
                                <th>Date:-</th>
                                <th>01/06/2021</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td rowspan="2">Type of Payment</td>
                                <td style="font-weight: bold">
                                  Professions Tax Act. 1975
                                </td>
                                <td colspan="2">Payee Details</td>
                              </tr>
                              <tr>
                                <td>
                                  <ol>
                                    <li>RC</li>
                                    <li>EC</li>
                                  </ol>
                                  ( Select the applicable one )
                                </td>
                                <td colspan="2">
                                  <p>PT( RC/EC )</p>
                                  <p>Number</p>
                                  <p>(Tin)</p>
                                  <p>27801522796P</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Location MUMBAI</td>
                                <td rowspan="3" colspan="2">
                                  <p>Full Name of the Tax Payer</p>
                                  <p style="font-weight: bold">
                                    {{ pt_calculation.site_name }}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td style="font-weight: bold" colspan="2">
                                  <center>Period</center>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p style="font-weight: bold">FROM</p>
                                  <p>{{ pt_calculation.from_date }}</p>
                                </td>
                                <td>
                                  <p style="font-weight: bold">TO</p>
                                  <p>{{ pt_calculation.to_date }}</p>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th>Account Head Details</th>
                                <th>Code</th>
                                <th>Amount in Rs,</th>
                                <th>Remarks if Any,</th>
                              </tr>
                              <tr>
                                <td>Amount of Tax</td>
                                <td>1</td>
                                <td>{{ pt_calculation.total_pt }}</td>
                                <td rowspan="8"></td>
                              </tr>
                              <tr>
                                <td>Interest Amount</td>
                                <td>2</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Penality Amount</td>
                                <td>3</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Composition Money</td>
                                <td>4</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Fine</td>
                                <td>5</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Fees</td>
                                <td>6</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Advances Payment</td>
                                <td>7</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td style="font-weight: bold">Total</td>
                                <td></td>
                                <td>{{ pt_calculation.total_pt }}</td>
                              </tr>
                              <tr>
                                <td colspan="5" rowspan="5">
                                  <p style="float: left">
                                    Amount in Words:
                                    {{ pt_calculation.total_pt_words }}
                                  </p>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <p style="float: right">
                                    Signature of Person has made payment
                                  </p>
                                </td>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <td colspan="4">
                                  <center>FOR BANKS/ TREASURY</center>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Payment Details</td>
                                <td colspan="2">For use in receiving Bank</td>
                              </tr>
                              <tr>
                                <td>Name of bank</td>
                                <td></td>
                                <td>Bank CIN</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name of Branch</td>
                                <td></td>
                                <td>Date</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Time</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Scroll No.</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Challan Not yet Generated</h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFPTChallan",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      states: [],
      state_id: "",
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showPTChallanTable: false,
      showPTChallan: false,
      from_date: "",
      to_date: "",
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();
    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    // this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      let states = masters.states;
      // this.states = [];
      states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.code,
        });
      });
      console.log(this.states);
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showPTChallan = false;
      this.showPTChallanTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let from_Date = moment(String(this.from_date)).format("YYYY-MM-DD");
        let to_Date = moment(String(this.to_date)).format("YYYY-MM-DD");
        // console.log(from);
        let pt_calculation = await axios.get(
          `/pt_reports/generate_pt?from_date=${from_Date}&to_date=${to_Date}&groupId=${this.group}&groupDivisionId=${this.groupDivision}&stateId=${this.state_id}`
        );
        this.pt_calculation = pt_calculation.data.data;
        console.log(pt_calculation);
        // let users = await axios.get(
        //   `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        // );
        // this.users = [];
        // users.data.data.forEach((user) => {
        //   if (
        //     user.employee_status == null ||
        //     (user.employee_status.code != "LEFT" &&
        //       user.employee_status.code != "TERMINATE")
        //   )
        //     this.users.push(user);
        // });
        // for (const user of this.users) {
        //   if (user.monthly_salary == null)
        //     this.initializeEmptyMonthlySalary(user);
        //   else this.getEarnedSalaryComponents(user);
        // }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showPTChallanTable = true;
        this.showPTChallan = false;
      }
      if (source == "generate") {
        this.showPTChallanTable = false;
        this.showPTChallan = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PTChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [480, 350],
          orientation: "l",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
