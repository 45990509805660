<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Salary Register
            <save-button @click="save"></save-button>&nbsp; &nbsp;
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          &nbsp; &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                  <div
                    class="col-md-3"
                    v-for="(category, c) in categories"
                    :key="`category${c}`"
                  >
                    <my-select
                      style="margin: 9px !important"
                      @change="selectCategory"
                      :selection.sync="category.selectedValue"
                      :suggestions="category.active_category_lists"
                      :placeholder="`Select ${category.category_name}`"
                      multiple="true"
                    >
                    </my-select>
                    <small v-if="isSaving">Saving...</small>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-2">
                    <my-checkbox
                      :selection.sync="isGenerateSalaryForAll"
                      placeholder="Generate Salary For All"
                      @change="toggleGenerateSalaryForAll"
                    ></my-checkbox>
                  </div>
                  <div class="col-md-4">
                    <button class="btn btn-primary" @click="searchData">
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <errors :data="errors" />
                  <section id="mydiv" v-if="showGeneratedSalary">
                    <!-- <div v-if="showGeneratedSalary"> -->
                    <div class="container-fluid">
                      <p style="font-size: 18px; font-weight: bold">
                        V ALLIANCE
                      </p>
                      <p style="font-size: 18px; font-weight: bold">
                        Salary / Wages Register For The Month {{ month_name }} -
                        {{ year }}.
                        <span
                          v-if="this.groupDivision"
                          style="font-size: 18px; font-weight: bold"
                          >For {{ users[0].group_division.division_name }}</span
                        >
                      </p>
                      <p style="float: right">Date 01/06/2021</p>
                      <table
                        id="table-example-1"
                        class="table table-responsive"
                        style="height=500px"
                      >
                        <thead>
                          <tr>
                            <th rowspan="6" style="position: inherit">Sr</th>
                            <th style="position: inherit">Employee Code</th>
                            <th style="position: inherit">Bank A/C Number</th>
                            <th style="position: inherit">Total Days</th>
                            <th style="position: inherit">Fixed Basic</th>
                            <th style="position: inherit">Fixed CCA</th>
                            <th style="position: inherit">Basic</th>
                            <th style="position: inherit">MEDICAL</th>
                            <th style="position: inherit">Reimbursements</th>
                            <th style="position: inherit">OVER TIME</th>
                            <th style="position: inherit">PROV. FUND</th>
                            <th style="position: inherit">TDS</th>
                            <th style="position: inherit">Life Insurance</th>
                            <th style="position: inherit">Gross Salary</th>
                            <th style="position: inherit" rowspan="6">
                              Signature
                            </th>
                          </tr>
                          <tr>
                            <th style="position: inherit">Employee Name</th>
                            <th style="position: inherit">Bank Name</th>
                            <th style="position: inherit">Days Paid</th>
                            <th style="position: inherit">Fixed D.A</th>
                            <th style="position: inherit">Fixed Medical</th>
                            <th style="position: inherit">D.A</th>
                            <th style="position: inherit">Other Allowances</th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit">Other Deduction</th>
                            <th style="position: inherit">E.S.I.C</th>
                            <th style="position: inherit">Mediclaim</th>
                            <th style="position: inherit">Uniform Deduction</th>
                            <th style="position: inherit">Total Earning</th>
                          </tr>
                          <tr>
                            <th style="position: inherit">DesigName</th>
                            <th style="position: inherit">Mode</th>
                            <th style="position: inherit">Absent Days</th>
                            <th style="position: inherit">Fixed H.R.A</th>
                            <th style="position: inherit">
                              Fixed Washing Allowances
                            </th>
                            <th style="position: inherit">H.R.A</th>
                            <th style="position: inherit">
                              Washing Allowances
                            </th>
                            <th style="position: inherit">Arrears</th>
                            <th style="position: inherit">
                              Pay Card Deduction
                            </th>
                            <th style="position: inherit">P.Tax</th>
                            <th style="position: inherit">Loan</th>
                            <th style="position: inherit">Mobile Deduction</th>
                            <th style="position: inherit">Total Deduction</th>
                          </tr>
                          <tr>
                            <th style="position: inherit">Date Joined</th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit">OT Hours</th>
                            <th style="position: inherit">Fixed Conveyance</th>
                            <th style="position: inherit">
                              Fixed Other Allowances
                            </th>
                            <th style="position: inherit">
                              Conveyance Allowances
                            </th>
                            <th style="position: inherit">Mobile Allowances</th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit">LWF EE</th>
                            <th style="position: inherit">Salary Advance</th>
                            <th style="position: inherit">
                              Accidental Insurance
                            </th>
                            <th style="position: inherit">Net Paid</th>
                          </tr>
                          <tr>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit">
                              Leave Encashment Gro
                            </th>
                            <th style="position: inherit">Bonus Gross</th>
                            <th style="position: inherit">CCA</th>
                            <th style="position: inherit">Leave Encashment</th>
                            <th style="position: inherit">Bonus</th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                            <th style="position: inherit"></th>
                          </tr>
                        </thead>
                        <tbody v-for="(user, at) in users" :key="`user${at}`">
                          <loading-table
                            :isLoading="isLoading"
                            cols="34"
                          ></loading-table>
                          <tr>
                            <td rowspan="5">
                              {{ serialNoStarting + at + 1 }}
                            </td>
                            <td>{{ user.employee_id }}</td>
                            <td>{{ user.bank_acc_no }}</td>
                            <td>{{ user.monthly_salary.payable_days }}</td>
                            <td>{{ user.user_salaries[0].fixed_basic }}</td>
                            <td>{{ user.user_salaries[0].fixed_cca }}</td>
                            <td>{{ user.monthly_salary.earned_basic }}</td>
                            <td>{{ user.monthly_salary.earned_medical }}</td>
                            <td>{{ user.monthly_salary.reimbursement }}</td>
                            <td>{{ user.monthly_salary.ot_amount }}</td>
                            <td>{{ user.monthly_salary.employee_pf }}</td>
                            <td>{{ user.monthly_salary.tds }}</td>
                            <td>{{ user.monthly_salary.lic }}</td>
                            <td>{{ user.user_salaries[0].gross_salary }}</td>
                          </tr>
                          <tr>
                            <td>{{ user.full_name }}</td>
                            <td>
                              {{ user.bank_name ? user.bank_name.name : "" }}
                            </td>
                            <td>{{ user.monthly_salary.days_present }}</td>
                            <td>{{ user.user_salaries[0].fixed_da }}</td>
                            <td>
                              {{
                                user.user_salaries[0].fixed_medical_allowance
                              }}
                            </td>
                            <td>{{ user.monthly_salary.earned_da }}</td>
                            <td>
                              {{ user.monthly_salary.earned_other_allowance }}
                            </td>
                            <td></td>
                            <td>{{ user.monthly_salary.others }}</td>
                            <td>{{ user.monthly_salary.employee_esic }}</td>
                            <td>{{ user.monthly_salary.mediclaim }}</td>
                            <td>{{ user.monthly_salary.uniform }}</td>
                            <td>{{ user.monthly_salary.total_earnings }}</td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                user.designation_category
                                  ? user.designation_category.category_list.code
                                  : ""
                              }}
                            </td>
                            <td>{{ user.monthly_salary.payment_mode }}</td>
                            <td>{{ user.monthly_salary.days_absent }}</td>
                            <td>{{ user.user_salaries[0].fixed_hra }}</td>
                            <td>
                              {{
                                user.user_salaries[0].fixed_washing_allowance
                              }}
                            </td>
                            <td>{{ user.monthly_salary.earned_hra }}</td>
                            <td>
                              {{ user.monthly_salary.earned_washing_allowance }}
                            </td>
                            <td>{{ user.monthly_salary.arrears }}</td>
                            <td>{{ user.monthly_salary.id_card }}</td>
                            <td>{{ user.monthly_salary.employee_pt }}</td>
                            <td>{{ user.monthly_salary.loan }}</td>
                            <td>{{ user.monthly_salary.mobile }}</td>
                            <td>
                              {{ user.monthly_salary.total_deductions }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ user.joined_on }}</td>
                            <td></td>
                            <td>{{ user.monthly_salary.ot_hrs }}</td>
                            <td>
                              {{ user.user_salaries[0].fixed_conveyance }}
                            </td>
                            <td>
                              {{ user.user_salaries[0].fixed_other_allowance }}
                            </td>
                            <td>
                              {{ user.monthly_salary.earned_conveyance }}
                            </td>
                            <td>
                              {{ user.monthly_salary.earned_mobile_allowance }}
                            </td>
                            <td></td>
                            <td></td>
                            <td>{{ user.monthly_salary.employee_lwf }}</td>
                            <td>{{ user.monthly_salary.salary_advance }}</td>
                            <td>{{ user.monthly_salary.accidental }}</td>
                            <td>{{ user.monthly_salary.total_payable }}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {{ user.user_salaries[0].leave_encashment }}
                            </td>
                            <td>{{ user.user_salaries[0].bonus }}</td>
                            <td>{{ user.monthly_salary.earned_cca }}</td>
                            <td>
                              {{ user.monthly_salary.earned_leave_encashment }}
                            </td>
                            <td>{{ user.user_salaries[0].bonus }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr style="font-weight: bold">
                            <td colspan="3" rowspan="5">
                              <center>Total</center>
                            </td>
                            <td>
                              {{ Calculation.Total_Days ? Calculation.Total_Days : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Fixed_Basic ? Calculation.Total_Fixed_Basic : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Fixed_CCA ? Calculation.Total_Fixed_CCA : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Basic ? Calculation.Total_Basic : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Medical ? Calculation.Total_Medical : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Reimbursement ? Calculation.Total_Reimbursement : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_OT ? Calculation.Total_OT : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_PF ? Calculation.Total_PF : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_TDS ? Calculation.Total_TDS : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_LIC ? Calculation.Total_LIC : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_All_Gross_Salary ? Calculation.Total_All_Gross_Salary : '0.00', }}
                            </td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td>
                              {{
                                Calculation.Total_Paid
                                  ? Calculation.Total_Paid
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{ Calculation.Total_Fixed_DA ? Calculation.Total_Fixed_DA : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Fixed_Medical ? Calculation.Total_Fixed_Medical : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_DA ? Calculation.Total_DA : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Other_Allowance ? Calculation.Other_Allowance : '0.00', }}
                            </td>
                            <td></td>
                            <td>
                              {{ Calculation.Total_Other_Deduction ? Calculation.Total_Other_Deduction : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_ESIC ? Calculation.Total_ESIC : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Mediclaim ? Calculation.Total_Mediclaim : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Uniform_Deduction ? Calculation.Total_Uniform_Deduction : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_All_Earning ? Calculation.Total_All_Earning : '0.00', }}
                            </td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td>
                              {{
                                Calculation.Total_Absent_Days
                                  ? Calculation.Total_Absent_Days
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{ Calculation.Total_Fixed_HRA ? Calculation.Total_Fixed_HRA : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Fixed_Washing_allowance ? Calculation.Total_Fixed_Washing_allowance : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_HRA ? Calculation.Total_HRA : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Washing_allowance ? Calculation.Washing_allowance : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Arrear ? Calculation.Total_Arrear : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Pay_Card_Deduction ? Calculation.Total_Pay_Card_Deduction : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_PT ? Calculation.Total_PT : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Loan ? Calculation.Total_Loan : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_Mobile_Deduction ? Calculation.Total_Mobile_Deduction : '0.00', }}
                            </td>
                            <td>
                              {{ Calculation.Total_All_Deduction ? Calculation.Total_All_Deduction : '0.00', }}
                            </td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td>
                              {{
                                Calculation.Total_Ot_hours
                                  ? Calculation.Total_Ot_hours
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Fixed_Conveyance
                                  ? Calculation.Total_Fixed_Conveyance
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Fixed_Other_Allowance
                                  ? Calculation.Total_Fixed_Other_Allowance
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Conveyance_Allowance
                                  ? Calculation.Total_Conveyance_Allowance
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Mobile_allowance
                                  ? Calculation.Total_Mobile_allowance
                                  : "0.00"
                              }}
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              {{
                                Calculation.Total_LWF
                                  ? Calculation.Total_LWF
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Salary_advance
                                  ? Calculation.Total_Salary_advance
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Accidental_insurance
                                  ? Calculation.Total_Accidental_insurance
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_All_Net_Pay
                                  ? Calculation.Total_All_Net_Pay
                                  : "0.00"
                              }}
                            </td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td></td>
                            <td>
                              {{
                                Calculation.Total_Leave_Enchasment_Gro
                                  ? Calculation.Total_Leave_Enchasment_Gro
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Bonus_Gross
                                  ? Calculation.Total_Bonus_Gross
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_CCA
                                  ? Calculation.Total_CCA
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Leave_Enchasment
                                  ? Calculation.Total_Leave_Enchasment
                                  : "0.00"
                              }}
                            </td>
                            <td>
                              {{
                                Calculation.Total_Bonus_Gross
                                  ? Calculation.Total_Bonus_Gross
                                  : "0.00"
                              }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <!-- </div> -->
                  </section>
                  <div v-else>
                    <h5>Register Not yet Generated</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import moment from "moment";
// import $ from "jquery";

export default {
  name: "SalaryRegister",
  data() {
    return {
      isLoading: false,
      isSaving: false,
      users: [],
      Calculation: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      categories: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      title: "",
      isGenerateSalaryForAll: false,
      showGeneratedSalary: false,
      attendances: [],
      serialNoStarting: 0,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();
    // this.keyBinding();

    // this.month_id = moment().format('M')
    this.month_name = moment().format("MMMM");
    this.year = moment().format("Y");

    this.fixColumns();
  },
  methods: {
    fixColumns() {},
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      // this.searchData()
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
      // this.searchData()
    },
    selectCategory() {
      this.isGenerateSalaryForAll = false;
    },

    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.categories.forEach((category) => {
        category.selectedValue = "";
      });
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData() {
      let selectedCategoriesArray = [];
      this.categories.forEach((category) => {
        selectedCategoriesArray.push(
          category.id + "-" + category.selectedValue
        );
      });
      this.getData(selectedCategoriesArray);
    },
    async getData(selectedCategoriesArray = []) {
      try {
        this.isLoading = true;
        let users = await axios.get(
          `/monthly_salaries/filter?monthId=${this.month_id}&year=${this.year}&selectedCategoriesArray=${selectedCategoriesArray}&searchText=${this.searchText}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.Calculation = users.data.data;

        console.log(this.users);
        // this.users = [];
        for (const user of users.data.data.salaryGeneratedUsers) {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          ) {
            this.users.push(user);
            if (user.monthly_salary == null) {
              this.initializeEmptyMonthlySalary(user);
            } else {
              this.getEarnedSalaryComponents(user);
            }
          }
          let designation_category = {};

          let designation_categories = user.user_categories.filter(
            (user_category) =>
              user_category.category.category_name == "DESIGNATION"
          );
          if (designation_categories.length > 0)
            designation_category = designation_categories[0];
          user.designation_category = designation_category;
        }
        // this.salaryGeneratedUsers = [];
        // for (const user of users.data.data.salaryGeneratedUsers) {
        //   if (
        //     user.employee_status == null ||
        //     (user.employee_status.code != "LEFT" &&
        //       user.employee_status.code != "TERMINATE")
        //   ) {
        //     this.users.push(user);
        //     if (user.monthly_salary == null) {
        //       this.initializeEmptyMonthlySalary(user);
        //     } else {
        //       this.getEarnedSalaryComponents(user);
        //     }
        //   }
        // }
        // this.exportToExcel();
        this.isLoading = false;
        this.showGeneratedSalary = true;
      } catch (e) {
        this.isLoading = false;
        this.showGeneratedSalary = false;
      }
    },
    async save() {
      this.isSaving = true;
      let monthly_salaries = [];
      this.users.forEach((user) => {
        monthly_salaries.push(user.monthly_salary);
      });
      let payload = {
        monthly_salaries: monthly_salaries,
      };
      await axios.post(`/monthly_salaries/multiple`, payload);
      this.isSaving = false;
    },
    initializeEmptyMonthlySalary(user) {
      user.monthly_salary = {
        is_salary_generated: this.isGenerateSalaryForAll,
        employee_id: user.id,
        month_id: this.month_id,
        year: this.year,
        days_present: moment(
          this.year + "-" + this.month_id,
          "YYYY-M"
        ).daysInMonth(),
        paid_leave: 0,
        days_absent: 0,
        extra_days: 0,
        payable_days: 0,
        ot_hrs: 0,
        ot_amount: 0,
        reimbursement: 0,
        earned_basic: 0,
        earned_da: 0,
        earned_conveyance: 0,
        earned_cca: 0,
        earned_medical: 0,
        earned_mobile_allowance: 0,
        earned_other_allowance: 0,
        earned_washing_allowance: 0,
        earned_leave_encashment: 0,
        earned_bonus: 0,
        earned_pf_wages: 0,
        earned_esic_wages: 0,
        total_earnings: 0,
        employee_pf: 0,
        employee_esic: 0,
        employee_pt: 0,
        employee_lwf: 0,
        tds: 0,
        loan: 0,
        salary_advance: 0,
        lic: 0,
        uniform: 0,
        mobile: 0,
        accidental: 0,
        mediclaim: 0,
        shoes: 0,
        id_card: 0,
        penalty: 0,
        others: 0,
        total_deductions: 0,
        total_payable: 0,
        remarks: "",
      };
      this.updateDays(user, "PRESENT");
    },
    updateDays(user, inputColumn = "") {
      let totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();

      let fixed_basic = user.salary ? parseFloat(user.salary.fixed_basic) : 0;
      let fixed_da = user.salary ? parseFloat(user.salary.fixed_da) : 0;
      let fixed_hra = user.salary ? parseFloat(user.salary.fixed_hra) : 0;
      let gross_salary = user.salary ? parseFloat(user.salary.gross_salary) : 0;
      let ot_rate = user.salary ? parseFloat(user.salary.ot_rate) : 0;

      let fixed_conveyance = user.salary
        ? parseFloat(user.salary.fixed_conveyance)
        : 0;
      let fixed_cca = user.salary ? parseFloat(user.salary.fixed_cca) : 0;
      let fixed_medical = user.salary
        ? parseFloat(user.salary.fixed_medical_allowance)
        : 0;
      let fixed_special = user.salary
        ? parseFloat(user.salary.fixed_special_allowance)
        : 0;
      let fixed_mobile_allowance = user.salary
        ? parseFloat(user.salary.fixed_mobile_allowance)
        : 0;
      let fixed_other_allowance = user.salary
        ? parseFloat(user.salary.fixed_other_allowance)
        : 0;
      let fixed_washing_allowance = user.salary
        ? parseFloat(user.salary.fixed_washing_allowance)
        : 0;
      let fixed_leave_encashment = user.salary
        ? parseFloat(user.salary.leave_encashment)
        : 0;
      let fixed_bonus = user.salary ? parseFloat(user.salary.bonus) : 0;

      let days_present = parseFloat(user.monthly_salary.days_present);
      days_present =
        days_present >= totalDaysInMonth ? totalDaysInMonth : days_present;
      let paid_leave = parseFloat(user.monthly_salary.paid_leave);
      let days_absent = parseFloat(user.monthly_salary.days_absent);
      let extra_days = parseFloat(user.monthly_salary.extra_days);
      let payable_days = parseFloat(user.monthly_salary.payable_days);

      let ot_hrs = parseFloat(user.monthly_salary.ot_hrs);
      let ot_amount = parseFloat(user.monthly_salary.ot_amount);
      let reimbursement = parseFloat(user.monthly_salary.reimbursement);
      let total_earnings = parseFloat(user.monthly_salary.total_earnings);

      let earned_basic = 0;
      let earned_da = 0;
      let earned_hra = 0;
      let earned_conveyance = 0;
      let earned_cca = 0;
      let earned_medical = 0;
      let earned_mobile_allowance = 0;
      let earned_other_allowance = 0;
      let earned_washing_allowance = 0;
      let earned_leave_encashment = 0;
      let earned_bonus = 0;

      let pf_wages = fixed_basic + fixed_da;
      if (user.group_division) {
        if (user.group_division.applicable_on == 1)
          pf_wages =
            gross_salary -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (user.group_division.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      } else if (user.group) {
        if (user.group.applicable_on == 1)
          pf_wages =
            gross_salary -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (user.group.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      }

      let earned_pf_wages = 0;
      let earned_esic_wages = 0;
      let earned_lwf_wages = 0;
      let earned_pt_wages = 0;

      let employee_pf = 0;
      let employee_pf_value = parseFloat(
        this.configurations.pf_configuration
          ? this.configurations.pf_configuration.employee_value
          : 0
      );
      let employee_pf_type = parseFloat(
        this.configurations.pf_configuration.employee_type
      );
      let employee_pf_max_wage = parseFloat(
        this.configurations.pf_configuration.max_wage
      );

      let employee_esic = 0;
      let employee_esic_value = parseFloat(
        this.configurations.esic_configuration
          ? this.configurations.esic_configuration.employee_value
          : 0
      );
      let employee_esic_type = parseFloat(
        this.configurations.esic_configuration.employee_type
      );
      let employee_esic_max_wage = parseFloat(
        this.configurations.esic_configuration.max_wage
      );

      let gender = user.gender;
      let employee_pt = 0;
      let pt_configurations = [];
      if (user.group_division)
        pt_configurations = this.configurations.pt_configurations.filter(
          (pt_configuration) =>
            pt_configuration.value_list_id == user.group_division.state_id
        );
      else if (user.group)
        pt_configurations = this.configurations.pt_configurations.filter(
          (pt_configuration) =>
            pt_configuration.value_list_id == user.group.state_id
        );
      else pt_configurations = [];

      let employee_lwf = 0;
      let lwf_configurations = [];
      if (user.group_division)
        lwf_configurations = this.configurations.lwf_configurations.filter(
          (lwf_configuration) =>
            lwf_configuration.value_list_id == user.group_division.state_id
        );
      else if (user.group)
        lwf_configurations = this.configurations.lwf_configurations.filter(
          (lwf_configuration) =>
            lwf_configuration.value_list_id == user.group.state_id
        );
      else lwf_configurations = [];
      let lwf_configuration =
        lwf_configurations.length > 0 ? lwf_configurations[0] : {};
      let employee_lwf_value = parseFloat(
        lwf_configuration ? lwf_configuration.employee_value : 0
      );
      let employee_lwf_type = parseFloat(lwf_configuration.employee_type);
      // let employee_lwf_max_wage = parseFloat(lwf_configuration.max_wage)
      let lwf_is_january = lwf_configuration.is_january;
      let lwf_is_february = lwf_configuration.is_february;
      let lwf_is_march = lwf_configuration.is_march;
      let lwf_is_april = lwf_configuration.is_april;
      let lwf_is_may = lwf_configuration.is_may;
      let lwf_is_june = lwf_configuration.is_june;
      let lwf_is_july = lwf_configuration.is_july;
      let lwf_is_august = lwf_configuration.is_august;
      let lwf_is_september = lwf_configuration.is_september;
      let lwf_is_october = lwf_configuration.is_october;
      let lwf_is_november = lwf_configuration.is_november;
      let lwf_is_december = lwf_configuration.is_december;

      let tds = parseFloat(user.monthly_salary.tds);
      let loan = parseFloat(user.monthly_salary.loan);
      let salary_advance = parseFloat(user.monthly_salary.salary_advance);
      let lic = parseFloat(user.monthly_salary.lic);
      let uniform = parseFloat(user.monthly_salary.uniform);
      let mobile = parseFloat(user.monthly_salary.mobile);
      let accidental = parseFloat(user.monthly_salary.accidental);
      let mediclaim = parseFloat(user.monthly_salary.mediclaim);
      let shoes = parseFloat(user.monthly_salary.shoes);
      let id_card = parseFloat(user.monthly_salary.id_card);
      let penalty = parseFloat(user.monthly_salary.penalty);
      let others = parseFloat(user.monthly_salary.others);
      let total_deductions = 0;
      let total_payable = 0;

      if (inputColumn == "PRESENT") {
        // user.monthly_salary.is_salary_generated = true
        days_absent = totalDaysInMonth - (days_present + paid_leave);
      }
      if (inputColumn == "ABSENT")
        days_present = totalDaysInMonth - (paid_leave + days_absent);
      if (inputColumn == "LEAVE") days_absent -= paid_leave;
      payable_days = days_present + paid_leave + extra_days;

      ot_amount = ot_hrs * ot_rate;
      total_earnings =
        (payable_days * gross_salary) / totalDaysInMonth +
        ot_amount +
        reimbursement;

      earned_basic = (fixed_basic / totalDaysInMonth) * payable_days;
      earned_da = (fixed_da / totalDaysInMonth) * payable_days;
      earned_hra = (fixed_hra / totalDaysInMonth) * payable_days;
      earned_conveyance = (fixed_conveyance / totalDaysInMonth) * payable_days;
      earned_cca = (fixed_cca / totalDaysInMonth) * payable_days;
      earned_medical = (fixed_medical / totalDaysInMonth) * payable_days;
      earned_mobile_allowance =
        (fixed_mobile_allowance / totalDaysInMonth) * payable_days;
      earned_other_allowance =
        (fixed_other_allowance / totalDaysInMonth) * payable_days;
      earned_washing_allowance =
        (fixed_washing_allowance / totalDaysInMonth) * payable_days;
      earned_leave_encashment =
        (fixed_leave_encashment / totalDaysInMonth) * payable_days;
      earned_bonus = (fixed_bonus / totalDaysInMonth) * payable_days;

      // Employee PF
      if (user.is_pf_applicable == 1) {
        if (employee_pf_type == 1) employee_pf = employee_pf_value;
        if (employee_pf_type == 2) {
          earned_pf_wages = (payable_days * pf_wages) / totalDaysInMonth;
          earned_pf_wages =
            earned_pf_wages > employee_pf_max_wage
              ? employee_pf_max_wage
              : earned_pf_wages;
          employee_pf = (employee_pf_value * earned_pf_wages) / 100;
        }
        employee_pf = Math.round(employee_pf);
      }

      // Employee ESIC
      if (user.is_esi_applicable == 1) {
        // earned_esic_wages = total_earnings != 0 ? Math.ceil(total_earnings - reimbursement - fixed_washing_allowance) : 0
        earned_esic_wages =
          total_earnings != 0
            ? total_earnings - reimbursement - fixed_washing_allowance
            : 0;
        if (employee_esic_type == 1)
          employee_esic =
            gross_salary > employee_esic_max_wage ? 0 : employee_esic_value;
        if (employee_esic_type == 2) {
          earned_esic_wages =
            gross_salary > employee_esic_max_wage ? 0 : earned_esic_wages;
          employee_esic = (employee_esic_value * earned_esic_wages) / 100;
        }
        employee_esic = Math.ceil(employee_esic);
      }

      // Employee PT
      if (user.is_pt_applicable == 1) {
        earned_pt_wages = Math.round(total_earnings);
        pt_configurations.forEach((pt_configuration) => {
          if (
            (earned_pt_wages > pt_configuration.min_wage &&
              earned_pt_wages < pt_configuration.max_wage) ||
            (earned_pt_wages > pt_configuration.min_wage &&
              pt_configuration.max_wage == 0)
          ) {
            if (
              (pt_configuration.is_men && gender == 0) ||
              (pt_configuration.is_women && gender == 1)
            )
              if (
                (this.month_id == 1 && pt_configuration.is_january == 1) ||
                (this.month_id == 2 && pt_configuration.is_february == 1) ||
                (this.month_id == 3 && pt_configuration.is_march == 1) ||
                (this.month_id == 4 && pt_configuration.is_april == 1) ||
                (this.month_id == 5 && pt_configuration.is_may == 1) ||
                (this.month_id == 6 && pt_configuration.is_june == 1) ||
                (this.month_id == 7 && pt_configuration.is_july == 1) ||
                (this.month_id == 8 && pt_configuration.is_august == 1) ||
                (this.month_id == 9 && pt_configuration.is_september == 1) ||
                (this.month_id == 10 && pt_configuration.is_october == 1) ||
                (this.month_id == 11 && pt_configuration.is_november == 1) ||
                (this.month_id == 12 && pt_configuration.is_december == 1)
              )
                if (pt_configuration.employee_type == 1)
                  employee_pt = pt_configuration.employee_value;
                else
                  employee_pt = Math.round(
                    (pt_configuration.employee_value * this.gross) / 100
                  );
          }
        });
      }

      // Employee LWF
      if (user.is_lwf_applicable == 1) {
        earned_lwf_wages = total_earnings - reimbursement;
        if (
          (this.month_id == 1 && lwf_is_january == 1) ||
          (this.month_id == 2 && lwf_is_february == 1) ||
          (this.month_id == 3 && lwf_is_march == 1) ||
          (this.month_id == 4 && lwf_is_april == 1) ||
          (this.month_id == 5 && lwf_is_may == 1) ||
          (this.month_id == 6 && lwf_is_june == 1) ||
          (this.month_id == 7 && lwf_is_july == 1) ||
          (this.month_id == 8 && lwf_is_august == 1) ||
          (this.month_id == 9 && lwf_is_september == 1) ||
          (this.month_id == 10 && lwf_is_october == 1) ||
          (this.month_id == 11 && lwf_is_november == 1) ||
          (this.month_id == 12 && lwf_is_december == 1)
        )
          if (employee_lwf_type == 1)
            // employee_lwf = earned_lwf_wages > employee_lwf_max_wage ? 0 : employee_lwf_value
            employee_lwf = earned_lwf_wages == 0 ? 0 : employee_lwf_value;
          else {
            // earned_lwf_wages = earned_lwf_wages > employee_lwf_max_wage ? 0 : earned_lwf_wages
            employee_lwf = (employee_lwf_value * earned_lwf_wages) / 100;
          }
      }

      total_deductions =
        employee_pf +
        employee_esic +
        employee_pt +
        employee_lwf +
        tds +
        loan +
        salary_advance +
        lic +
        uniform +
        mobile +
        accidental +
        mediclaim +
        shoes +
        id_card +
        penalty +
        others;

      total_payable = total_earnings - total_deductions;

      user.monthly_salary.days_present = days_present;
      user.monthly_salary.paid_leave = paid_leave;
      user.monthly_salary.days_absent = days_absent;
      user.monthly_salary.payable_days = payable_days;
      user.monthly_salary.ot_amount = Math.round(ot_amount);
      user.monthly_salary.total_earnings = Math.round(total_earnings);
      user.monthly_salary.earned_basic = Math.round(earned_basic);
      user.monthly_salary.earned_da = Math.round(earned_da);
      user.monthly_salary.earned_hra = Math.round(earned_hra);
      user.monthly_salary.earned_conveyance = Math.round(earned_conveyance);
      user.monthly_salary.earned_cca = Math.round(earned_cca);
      user.monthly_salary.earned_medical = Math.round(earned_medical);
      user.monthly_salary.earned_mobile_allowance = Math.round(
        earned_mobile_allowance
      );
      user.monthly_salary.earned_other_allowance = Math.round(
        earned_other_allowance
      );
      user.monthly_salary.earned_washing_allowance = Math.round(
        earned_washing_allowance
      );
      user.monthly_salary.earned_leave_encashment = Math.round(
        earned_leave_encashment
      );
      user.monthly_salary.earned_bonus = Math.round(earned_bonus);
      // user.monthly_salary.earned_pf_wages = (earned_pf_wages).toFixed(2)
      user.monthly_salary.earned_pf_wages = Math.round(earned_pf_wages);
      user.monthly_salary.earned_esic_wages = Math.round(earned_esic_wages);
      user.monthly_salary.employee_pf = Math.round(employee_pf);
      user.monthly_salary.employee_esic = Math.ceil(employee_esic);
      user.monthly_salary.employee_pt = Math.round(employee_pt);
      user.monthly_salary.employee_lwf = Math.round(employee_lwf);
      user.monthly_salary.total_deductions = Math.round(total_deductions);
      user.monthly_salary.total_payable = Math.round(total_payable);
    },

    getEarnedSalaryComponents(user) {
      let totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();

      let fixed_basic = user.salary ? parseFloat(user.salary.fixed_basic) : 0;
      let fixed_da = user.salary ? parseFloat(user.salary.fixed_da) : 0;
      let fixed_hra = user.salary ? parseFloat(user.salary.fixed_hra) : 0;
      let fixed_conveyance = user.salary
        ? parseFloat(user.salary.fixed_conveyance)
        : 0;
      let fixed_cca = user.salary ? parseFloat(user.salary.fixed_cca) : 0;
      let fixed_medical = user.salary
        ? parseFloat(user.salary.fixed_medical_allowance)
        : 0;
      let fixed_mobile_allowance = user.salary
        ? parseFloat(user.salary.fixed_mobile_allowance)
        : 0;
      let fixed_other_allowance = user.salary
        ? parseFloat(user.salary.fixed_other_allowance)
        : 0;
      let fixed_washing_allowance = user.salary
        ? parseFloat(user.salary.fixed_washing_allowance)
        : 0;
      let fixed_leave_encashment = user.salary
        ? parseFloat(user.salary.leave_encashment)
        : 0;
      let fixed_bonus = user.salary ? parseFloat(user.salary.bonus) : 0;

      let earned_basic =
        (fixed_basic / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_da =
        (fixed_da / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_hra =
        (fixed_hra / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_conveyance =
        (fixed_conveyance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_cca =
        (fixed_cca / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_medical =
        (fixed_medical / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_mobile_allowance =
        (fixed_mobile_allowance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_other_allowance =
        (fixed_other_allowance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_washing_allowance =
        (fixed_washing_allowance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_leave_encashment =
        (fixed_leave_encashment / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_bonus =
        (fixed_bonus / totalDaysInMonth) * user.monthly_salary.payable_days;

      user.monthly_salary.earned_basic = Math.round(earned_basic);
      user.monthly_salary.earned_da = Math.round(earned_da);
      user.monthly_salary.earned_hra = Math.round(earned_hra);
      user.monthly_salary.earned_conveyance = Math.round(earned_conveyance);
      user.monthly_salary.earned_cca = Math.round(earned_cca);
      user.monthly_salary.earned_medical = Math.round(earned_medical);
      user.monthly_salary.earned_mobile_allowance = Math.round(
        earned_mobile_allowance
      );
      user.monthly_salary.earned_other_allowance = Math.round(
        earned_other_allowance
      );
      user.monthly_salary.earned_washing_allowance = Math.round(
        earned_washing_allowance
      );
      user.monthly_salary.earned_leave_encashment = Math.round(
        earned_leave_encashment
      );
      user.monthly_salary.earned_bonus = Math.round(earned_bonus);
    },

    exportToExcel() {
      this.title =
        "Salary / Wages Register For the Month of " +
        this.month_name +
        " " +
        this.year;

      this.attendances = [];
      let sr_no = 1;
      this.users.forEach((user) => {
        if (
          user.monthly_salary &&
          user.monthly_salary.is_salary_generated == true
        ) {
          // Branch
          let designation_category = {};
          let designation_categories = user.user_categories.filter(
            (user_category) =>
              user_category.category.category_name == "DESIGNATION"
          );
          if (designation_categories.length > 0)
            designation_category = designation_categories[0];
          // Branch
          let branch_category = {};
          let branch_categories = user.user_categories.filter(
            (user_category) => user_category.category.category_name == "BRANCH"
          );
          if (branch_categories.length > 0)
            branch_category = branch_categories[0];
          this.attendances.push({
            sr_no: sr_no,
            employee_id: user.id,
            employee_code: user.employee_id,
            division_name: user.group_division
              ? user.group_division.division_name
              : "",
            employee_name: user.full_name,
            father_name: user.father_name,
            designation_name: designation_category.category_list
              ? designation_category.category_list.code
              : "",
            date_joined: user.joined_on
              ? moment(user.joined_on).format("DD-MM-YYYY")
              : "",
            date_of_birth: user.dob
              ? moment(user.dob).format("DD-MM-YYYY")
              : "",
            gender: user.gender == 0 ? "MALE" : "FEMALE",
            uan_number: user.uan_no ? `'${user.uan_no}` : "",
            pf_number: user.pf_no ? `'${user.pf_no}` : "",
            adhaar_number: user.aadhar_no ? `'${user.aadhar_no}` : "",
            esic_number: user.esi_number ? `'${user.esi_number}` : "",
            // esic_flag: user.is_esi_applicable,
            account_no: user.bank_acc_no ? `'${user.bank_acc_no}` : "",
            ifsc_code: user.bank_ifsc_code,
            bank_name: user.bank_name ? user.bank_name.name : "",
            mode: user.bank_payment_type ? user.bank_payment_type.code : "",
            ot_rate: user.salary ? user.salary.ot_rate : "",
            gross_salary: user.salary ? user.salary.gross_salary : "",
            total_days: moment(
              this.year + "-" + this.month_id,
              "YYYY-M"
            ).daysInMonth(),
            days_present: user.monthly_salary.days_present,
            // priv_leave: 'Priv Leave',
            absent_days: user.monthly_salary.days_absent,
            payable_days: user.monthly_salary.payable_days,
            ot_hrs: user.monthly_salary.ot_hrs,
            ot_amount: user.monthly_salary.ot_amount,
            reimbursement: user.monthly_salary.reimbursement,
            // incentive: 'Incentive',
            // attendance_bonus: 'Attendance Bonus',
            basic: user.monthly_salary.earned_basic,
            da: user.monthly_salary.earned_da,
            hra: user.monthly_salary.earned_hra,
            conveyance_allowance: user.monthly_salary.earned_conveyance,
            cca: user.monthly_salary.earned_cca,
            medical: user.monthly_salary.earned_medical,
            mobile_allowance: user.monthly_salary.earned_mobile_allowance,
            other_allowance: user.monthly_salary.earned_other_allowance,
            washing_allowance: user.monthly_salary.earned_washing_allowance,
            leave_encashment: user.monthly_salary.earned_leave_encashment,
            bonus: user.monthly_salary.earned_bonus,
            pf_wages: user.monthly_salary.earned_pf_wages,
            esic_wages: user.monthly_salary.earned_esic_wages,
            total_earning: user.monthly_salary.total_earnings,
            pf: user.monthly_salary.employee_pf,
            employee_esic: user.monthly_salary.employee_esic,
            employee_pt: user.monthly_salary.employee_pt,
            employee_lwf: user.monthly_salary.employee_lwf,
            tds: user.monthly_salary.tds,
            loan: user.monthly_salary.loan,
            salary_advance: user.monthly_salary.salary_advance,
            lic: user.monthly_salary.lic,
            uniform: user.monthly_salary.uniform,
            mobile: user.monthly_salary.mobile,
            accidental: user.monthly_salary.accidental,
            mediclaim: user.monthly_salary.mediclaim,
            shoes: user.monthly_salary.shoes,
            id_card: user.monthly_salary.id_card,
            penalty: user.monthly_salary.penalty,
            others: user.monthly_salary.others,
            total_deductions: user.monthly_salary.total_deductions,
            total_payable: user.monthly_salary.total_payable,
            branch: branch_category.category_list
              ? branch_category.category_list.code
              : "",
            state: user.group_division
              ? user.group_division.state
                ? user.group_division.state.code
                : ""
              : "",
            group_name: user.group ? user.group.name : "",
            remarks: user.monthly_salary.remarks,
            // employer_pf: ,
            // employer_esic: ,
            // employer_lwf: ,
            // e_bonus: ,
            // leave_salary: ,
          });
          sr_no++;
        }
      });
    },

    changeSalaryStatus(user) {
      if (!user.monthly_salary.is_salary_generated) {
        user.monthly_salary.days_absent = moment(
          this.year + "-" + this.month_id,
          "YYYY-M"
        ).daysInMonth();
        user.monthly_salary.ot_hrs = 0;
        user.monthly_salary.reimbursement = 0;
        user.monthly_salary.tds = 0;
        user.monthly_salary.loan = 0;
        user.monthly_salary.salary_advance = 0;
        user.monthly_salary.lic = 0;
        user.monthly_salary.uniform = 0;
        user.monthly_salary.mobile = 0;
        user.monthly_salary.accidental = 0;
        user.monthly_salary.mediclaim = 0;
        user.monthly_salary.shoes = 0;
        user.monthly_salary.id_card = 0;
        user.monthly_salary.penalty = 0;
        user.monthly_salary.others = 0;
        user.monthly_salary.total_deductions = 0;
        user.monthly_salary.total_payable = 0;
        this.updateDays(user, "ABSENT");
      }
    },

    toggleGenerateSalaryForAll() {
      this.getData();
    },
  },
};
</script>
