<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Leave Register
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          &nbsp; &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-2">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="from_month"
                      :suggestions="months"
                      placeholder="Select From month"
                    ></my-select>
                  </div>
                  <div class="col-md-2">
                    <my-select
                      :selection.sync="from_year"
                      :suggestions="years"
                      placeholder="Select from year"
                    ></my-select>
                  </div>
                  <div class="col-md-2">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="to_month"
                      :suggestions="months"
                      placeholder="Select To month"
                    ></my-select>
                  </div>
                  <div class="col-md-2">
                    <my-select
                      :selection.sync="to_year"
                      :suggestions="years"
                      placeholder="Select to year"
                    ></my-select>
                  </div>
                  <div class="col-md-4">
                    <!-- <button @click="searchData('search')">Search</button>&nbsp;&nbsp; -->
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                    <download-excel
                      :title="title"
                      style="float: right; margin-right: 40px"
                      class="btn btn-default"
                      :data="leaves"
                      :fields="excel_fields"
                      worksheet="Leaves"
                      name="LeaveRegister.xls"
                    >
                      <a href="#" class="download">Export to Excel</a>
                    </download-excel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showLeaveRegisterTable"></div>
                <section id="mydiv" v-if="showLeaveRegister">
                  <div class="container-fluid" style="margin-top: 20px">
                    <p style="font-size: 18px; font-weight: bold">
                      LEAVE REGISTER : FOR THE MONTH:
                      {{ from_month | mm }} {{ this.from_year }} TO
                      {{ to_month | mm }} {{ this.to_year }}
                    </p>
                    <table class="col-md-12" border="2px">
                      <thead>
                        <tr>
                          <th>Employee Code</th>
                          <th>Division Name</th>
                          <th>Employee Name</th>
                          <th>Date Of Joining</th>
                          <th
                            v-for="(month, m) in header_months"
                            :key="`month${m}`"
                          >
                            {{ month.month | mm }} {{ month.year }}
                          </th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody v-for="(user, at) in users" :key="`user${at}`">
                        <tr>
                          <td rowspan="5">
                            {{ user.employee_id }}
                          </td>
                          <td rowspan="5">
                            {{
                              user.group_division
                                ? user.group_division.division_name
                                : ""
                            }}
                          </td>
                          <td>{{ user.full_name }}</td>
                          <td rowspan="5">{{ user.joining_date }}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>No of Days</td>
                          <td
                            v-for="(month, m) in header_months"
                            :key="`month${m}`"
                          >
                            {{
                              user.filtered_monthly_salaries
                                ? user.filtered_monthly_salaries[m]
                                  ? user.filtered_monthly_salaries[m]
                                      .days_in_month
                                  : "0"
                                : "0"
                            }}
                          </td>
                          <td>{{ user.total_days_in_month }}</td>
                        </tr>
                        <tr>
                          <td>Days Paid</td>
                          <td
                            v-for="(month, m) in header_months"
                            :key="`month${m}`"
                          >
                            {{
                              user.filtered_monthly_salaries
                                ? user.filtered_monthly_salaries[m]
                                  ? user.filtered_monthly_salaries[m]
                                      .payable_days
                                  : "0"
                                : "0"
                            }}
                          </td>
                          <td>{{ user.total_Paybale_days }}</td>
                        </tr>
                        <tr>
                          <td>Calculated Amt</td>
                          <td
                            v-for="(month, m) in header_months"
                            :key="`month${m}`"
                          >
                            {{
                              user.filtered_monthly_salaries
                                ? user.filtered_monthly_salaries[m]
                                  ? user.filtered_monthly_salaries[m]
                                      .total_payable
                                  : "0"
                                : "0"
                            }}
                          </td>
                          <td>{{ user.total_Calculated_amt }}</td>
                        </tr>
                        <tr>
                          <td>Leave Salary</td>
                          <td
                            v-for="(month, m) in header_months"
                            :key="`month${m}`"
                          >
                            {{
                              user.filtered_monthly_salaries
                                ? user.filtered_monthly_salaries[m]
                                  ? user.filtered_monthly_salaries[m]
                                      .leave_salary
                                  : "0"
                                : "0"
                            }}
                          </td>
                          <td>{{ user.total_Leave_salary }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFLeaveRegister",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      from_month: "",
      from_year: "",
      to_month: "",
      to_year: "",
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      months: [],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showLeaveRegisterTable: false,
      showLeaveRegister: false,
      excel_fields: {
        "Sr No": "sr_no",
        Title: "title",
        "Employee Name": "employee_name",
        "Employee Code": "employee_code",
        "Division Name": "division_name",
        "Date of Joining": "joined_on",
        // April: "april",
        // May: "may",
        // June: "june",
        // July: "july",
        // August: "august",
        // September: "september",
        // October: "october",
        // November: "november",
        // December: "december",
        // January: "january",
        // February: "february",
        // March: "march",
        Total: "total",
      },
      leaves: [],
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;
      // console.log(masters);
      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      // console.log(this.groups);
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      // console.log(group);
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showLeaveRegister = false;
      this.showLeaveRegisterTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        // this.users = [];
        // let users = await axios.get(
        //   `/leave_register?monthId=${this.month_id}&from_year=${this.from_year}&to_year=${this.to_year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        // );
        let users = await axios.get(
          `/leave_register?from_month=${this.from_month}&to_month=${this.to_month}&from_year=${this.from_year}&to_year=${this.to_year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = users.data.data;
        this.header_months = users.data.header_months;
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showLeaveRegisterTable = true;
        this.showLeaveRegister = false;
      }
      if (source == "generate") {
        this.showLeaveRegisterTable = false;
        this.showLeaveRegister = true;
      }
      this.exportToExcel();
    },
    exportToExcel() {
      let f_month = moment(this.from_month, "M").format("MMMM");
      let t_month = moment(this.to_month, "M").format("MMMM");
      this.title =
        "Leave Register For the " +
        f_month +
        " " +
        this.from_year +
        " To " +
        t_month +
        " " +
        this.to_year;
      this.leaves = [];
      this.header_months.forEach((hm) => {
        let HM_Month = moment(hm.month, "M").format("MMMM") + hm.year;
        this.excel_fields[HM_Month] = hm.month + hm.year;
      });
      let sr_no = 1;
      this.users.forEach((employee) => {
        let leave = {
          sr_no: sr_no,
          employee_code: employee.employee_id,
          division_name: employee.group_division
            ? employee.group_division.division_name
            : "",
          title: employee.title,
          employee_name: employee.full_name,
          joined_on: employee.joined_on ? employee.joined_on : "",
          total: employee.total_Leave_salary,
        };

        // leave.april = 0;
        // leave.may = 0;
        // leave.june = 0;
        // leave.july = 0;
        // leave.august = 0;
        // leave.september = 0;
        // leave.october = 0;
        // leave.november = 0;
        // leave.december = 0;
        // leave.january = 0;
        // leave.february = 0;
        // leave.march = 0;
        this.header_months.forEach((header_month) => {
          let Month = employee.filtered_monthly_salaries.find((mm) => {
            if (
              mm.month_id == header_month.month &&
              mm.year == header_month.year
            )
              return mm;
          });
          leave[header_month.month + header_month.year] = Month
            ? Month.leave_salary
            : "0";
          Month = [];
          // April
          // if (header_month.month <= 4 && header_month.month > 3) {
          //   leave.april = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // May
          // if (header_month.month <= 5 && header_month.month > 3) {
          //   leave.may = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // June
          // if (header_month.month <= 6 && header_month.month > 3) {
          //   leave.june = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // July
          // if (header_month.month <= 7 && header_month.month > 3) {
          //   leave.july = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // August
          // if (header_month.month <= 8 && header_month.month > 3) {
          //   leave.august = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // September
          // if (header_month.month <= 9 && header_month.month > 3) {
          //   leave.september = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // Octumber
          // if (header_month.month <= 10 && header_month.month > 3) {
          //   leave.october = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // November
          // if (header_month.month <= 11 && header_month.month > 3) {
          //   leave.november = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // December
          // if (header_month.month <= 12 && header_month.month > 3) {
          //   leave.december = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // Januaury
          // if (header_month.month <= 1 && header_month.month < 3) {
          //   leave.january = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // February
          // if (header_month.month <= 2 && header_month.month < 3) {
          //   leave.february = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
          // // March
          // if (header_month.month <= 3 && header_month.month == 3) {
          //   leave.march = employee.filtered_monthly_salaries[hm]
          //     ? employee.filtered_monthly_salaries[hm].leave_salary
          //     : 0.0;
          // }
        });

        this.leaves.push(leave);
        sr_no++;
      });
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "LeaveRegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
