<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Pay Slip 2
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          <my-search
            :search="searchText"
            placeholder="Search here..."
            @searchData="search"
          ></my-search>
          <br /><br />
          <div class="row">
            <div class="col-md-4">
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="month_id"
                :suggestions="months"
                placeholder="Select month"
              ></my-select>
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="year"
                :suggestions="years"
                placeholder="Select year"
              ></my-select>
              <br /><br />
              <!-- <button @click="searchData('search')">Search</button>&nbsp;&nbsp; -->
              <button @click="searchData('generate')">Generate</button
              >&nbsp;&nbsp;
              <button @click="generatePDF">Save & Print</button>
              <br /><br />
            </div>
            <div class="col-md-offset-2 col-md-6">
              <my-select
                style="margin: 9px !important"
                @change="selectDivision"
                disableFormControl="true"
                :selection.sync="group"
                :suggestions="groups"
                :placeholder="`Select GROUP`"
              >
              </my-select>
              <my-select
                style="margin: 9px !important"
                disableFormControl="true"
                :selection.sync="groupDivision"
                :suggestions="groupDivisions"
                :placeholder="`Select DIVISION`"
              >
              </my-select>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showPaySlip2Table"></div>
                <section id="mydiv" v-if="showPaySlip2">
                  <div class="container-fluid">
                    <div class="row heading-bg">
                      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                        <h5 class="txt-dark">LOGO</h5>
                      </div>
                      <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12"></div>
                      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                        <h5 class="txt-dark">
                          <h3>V ALLIANCE</h3>
                          <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
                          <p>CBD Belapur, Navi Mumbai-4000614.</p>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="container-fluid row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <br />
                      <p>EMP Code : abd</p>
                      <p>ESIC No : abd</p>
                      <p>Joining Date : 12/12/2020</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>Payslip for the month : April 2021</p>
                      <p>Name : XYZ</p>
                      <p>Department : Operation</p>
                      <p>UAN No : 8522558</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>Branch : Kolkata</p>
                      <p>Designation : Office Boy</p>
                      <p>Division : XYZ</p>
                      <p>PF No : XYZ/5558882</p>
                    </div>
                  </div>
                  <hr />
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <!-- <br /> -->
                      <p>Days Paid : 30.00</p>
                      <p>OT hours : 15.00</p>
                      <!-- <p>SL : 0.00</p>
        <p>Bal SL : 0.00</p> -->
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>Days Peresent : 26.00</p>
                      <!-- <p>Late Hours : 0.00</p> -->
                      <!-- <p>CL : 0.00</p>
        <p>Bal CL : 0.00</p> -->
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>W.Off/Pd.Off : 4.00/0.00</p>
                      <!-- <p>Holiday (P) : 0.00</p> -->
                      <p>PL : 0.00</p>
                      <p>Bal PL No : 0.00</p>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>LWP/Absent : 0.00/0.00</p>
                      <!-- <p>Holiday (A) : 0.00</p> -->
                      <!-- <p>CO + / CO - : 0.00/0.00</p>
        <p>Bal CO : 0.00</p> -->
                    </div>
                  </div>
                  <hr />
                  <div class="container-fluid">
                    <table class="table table-responsive col-md-10">
                      <thead>
                        <tr>
                          <th>Earning & Reimbursements</th>
                          <th>Gross Amt</th>
                          <th>Actual Amt</th>
                          <th>Deduction & Recoveries</th>
                          <th>Gross Amt</th>
                          <th>Actual Amt</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Basic Salary</td>
                          <td>10856.00</td>
                          <td>10856.00</td>
                          <td>PROV. FUND</td>
                          <td>1350.00</td>
                          <td>1350.00</td>
                        </tr>
                        <tr>
                          <td>Basic Arrears</td>
                          <td></td>
                          <td>26250.00</td>
                          <td>PROV. FUND Arrears</td>
                          <td></td>
                          <td>969.00</td>
                        </tr>
                        <tr>
                          <td>D.A</td>
                          <td>390.00</td>
                          <td>390.00</td>
                          <td>E.S.I.C</td>
                          <td>129.00</td>
                          <td>156.00</td>
                        </tr>
                        <tr>
                          <td>D.A Arrears</td>
                          <td></td>
                          <td>-18203.00</td>
                          <td>E.S.I.C Arrears</td>
                          <td></td>
                          <td>84.00</td>
                        </tr>
                        <tr>
                          <td>H.R.A</td>
                          <td>362.00</td>
                          <td>362.00</td>
                          <td>p. Tax</td>
                          <td>0.00</td>
                          <td>200.00</td>
                        </tr>
                        <tr>
                          <td>H.R.A Arrears</td>
                          <td></td>
                          <td>402.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>C.C.A</td>
                          <td>4275.00</td>
                          <td>4275.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Leave Encashment</td>
                          <td>1083.00</td>
                          <td>1083.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Leave Encashment Arrears</td>
                          <td></td>
                          <td>573.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Over Time</td>
                          <td>0.00</td>
                          <td>3565.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Over Time Arrears</td>
                          <td></td>
                          <td>2108.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Total Earnings</th>
                          <th>17166.00</th>
                          <th>31861.00</th>
                          <th>
                            <p>Total Deductions</p>
                            <p>Net Pay</p>
                          </th>
                          <th>1479.00</th>
                          <th>
                            <p>2759.00</p>
                            <p>29192.00</p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Net Pay: Rupees Twenty Nine Thousand One Hundred Two Only
                    </p>
                    <p>
                      *This is computer generated salary slip and requires no
                      physical signature or stamp*
                    </p>
                    <p>Bank Name: ICICI Bank - Bank A/c No. : 55121545155421</p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFPaySlip2",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showPaySlip2Table: false,
      showPaySlip2: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showPaySlip2 = false;
      this.showPaySlip2Table = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showPaySlip2Table = true;
        this.showPaySlip2 = false;
      }
      if (source == "generate") {
        this.showPaySlip2Table = false;
        this.showPaySlip2 = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PaySlip2.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
