<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Daily Punch Log
            <save-button @click="save"></save-button>
            &nbsp;
            <refresh-button @click="refreshData"></refresh-button>
            &nbsp;
          </h5>
          <small v-if="isSaving">Saving...</small>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Payroll</li>
            <li class="active">Daily Punch Log</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        :search="searchText"
                        placeholder="Search..."
                        @searchData="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <my-datepicker
                        style="margin: 9px !important"
                        :date.sync="punch_date"
                        placeholder="Punch Date"
                      ></my-datepicker>
                    </div>
                    <div class="col-md-3">
                      <my-select
                        style="margin: 9px !important"
                        @change="updateMonth"
                        :selection.sync="year"
                        :suggestions="years"
                        placeholder="Select year"
                      ></my-select>
                    </div>
                    <div class="col-md-3">
                      <my-select
                        style="margin: 9px !important"
                        @change="selectDivision"
                        :selection.sync="group"
                        :suggestions="groups"
                        :placeholder="`Select GROUP`"
                      >
                      </my-select>
                    </div>
                    <div class="col-md-3">
                      <my-select
                        style="margin: 9px !important"
                        :selection.sync="groupDivision"
                        :suggestions="groupDivisions"
                        :placeholder="`Select DIVISION`"
                      >
                      </my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-3"
                      v-for="(category, c) in categories"
                      :key="`category${c}`"
                    >
                      <my-select
                        style="margin: 9px !important"
                        @change="selectCategory"
                        :selection.sync="category.selectedValue"
                        :suggestions="category.active_category_lists"
                        :placeholder="`Select ${category.category_name}`"
                        multiple="true"
                      >
                      </my-select>
                    </div>
                    <div class="col-md-3 pull-right">
                      <button
                        class="btn btn-primary btn-sm pull-right"
                        @click="searchData"
                      >
                        SEARCH
                      </button>
                    </div>
                    <small v-if="isSaving">Saving...</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Title -->
        <!-- Row -->
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default card-view">
              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4">
                  <download-excel
                    :title="title"
                    class="btn btn-default pull-right"
                    style="margin-bottom: 5px"
                    :data="attendances"
                    :fields="excel_fields"
                    worksheet="Attendances"
                    name="Attendance.xls"
                  >
                    <a href="#" class="download">Export to Excel</a>
                  </download-excel>
                </div>
              </div>
              <div class="panel-wrapper collapse in">
                <div class="panel-body">
                  <div class="table-wrap">
                    <!-- <div class="outer"> -->
                    <div class="inner" style="height: 700px">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th style="z-index: 2">Sr. No.</th>
                            <th>Emp Code</th>
                            <th>Name</th>
                            <th v-for="c in totalHours" :key="`hour${c}`">
                              {{ c }}
                            </th>
                            <th>Total Hour</th>
                          </tr>
                        </thead>
                        <tbody>
                          <loading-table
                            :isLoading="isLoading"
                            cols="34"
                          ></loading-table>
                          <tr v-for="(user, us) in users" :key="`user${us}`">
                            <td class="first-col">
                              {{ us + 1 }}
                            </td>
                            <td class="second-col">{{ user.emp_code }}</td>
                            <td class="third-col">{{ user.full_name }}</td>
                            <td v-for="c in totalHours" :key="`hour${c}`">
                              <div v-if="user[c]">
                                <div
                                  v-for="(slot, hr) in user[c]"
                                  :key="`slot${hr}`"
                                  style="width: 200px"
                                >
                                  <div v-if="slot.state == 'IN'">
                                    <span style="font-weight: bold">IN</span>:
                                    {{ slot.time }}
                                    <br />
                                    <span style="font-weight: bold">At</span>:
                                    {{ slot.address }}
                                    <br />
                                    <span
                                      v-if="slot.remarks"
                                      style="font-weight: bold"
                                      >Remark:</span
                                    >
                                    {{ slot.remarks }}
                                  </div>
                                  <br />

                                  <div v-if="slot.state == 'OUT'">
                                    <span style="font-weight: bold">OUT</span> :
                                    {{ slot.time }} <br />
                                    <span style="font-weight: bold">From</span>:
                                    {{ slot.address }}
                                    <br />
                                    <span
                                      v-if="slot.remarks"
                                      style="font-weight: bold"
                                      >Remark:</span
                                    >
                                    {{ slot.remarks }}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p style="width: 200px">
                                {{ user.time_diff }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- </div> -->

                    <errors :data="errors" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Row -->
      </div>
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import moment from "moment";

export default {
  name: "MonthlyAttendance",
  data() {
    return {
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      punch_date: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      categories: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      title: "",
      excel_fields: {
        "Sr No": "sr_no",
        "Emp Code": "employee_code",
        "Division Name": "division_name",
        "Employee Name": "employee_name",
        "Father Name": "father_name",
        "Designation Name": "designation_name",
      },
      attendances: [],
      totalDaysInMonth: "",
      totalHours: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ],
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    this.month_name = moment().format("MMMM");
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
      // this.searchData()
    },
    selectCategory() {
      this.isGenerateSalaryForAll = false;
    },

    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.categories.forEach((category) => {
        category.selectedValue = "";
      });
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData() {
      let selectedCategoriesArray = [];
      this.categories.forEach((category) => {
        selectedCategoriesArray.push(
          category.id + "-" + category.selectedValue
        );
      });
      this.getData(selectedCategoriesArray);
    },
    async getData(selectedCategoriesArray = []) {
      try {
        this.isLoading = true;
        this.users = [];
        let punch_Date = moment(String(this.punch_date)).format("YYYY-MM-DD");

        let users = await axios.get(
          `/user_punches/daily_punch_log?punch_date=${punch_Date}&selectedCategoriesArray=${selectedCategoriesArray}&searchText=${this.searchText}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = users.data.data;
        // console.log(this.users);
        this.exportToExcel();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async save() {
      this.isSaving = true;
      let monthly_salaries = [];
      this.users.forEach((user) => {
        monthly_salaries.push(user.monthly_salary);
      });
      let payload = {
        monthly_salaries: monthly_salaries,
      };
      await axios.post(`/monthly_salaries/multiple`, payload);
      this.isSaving = false;
    },

    exportToExcel() {
      this.title =
        "Attendance Register For the Month of " +
        this.month_name +
        " " +
        this.year;

      this.attendances = [];
      let sr_no = 1;
      this.users.forEach((user) => {
        // Branch
        let designation_category = {};
        let designation_categories = user.user_categories.filter(
          (user_category) =>
            user_category.category.category_name == "DESIGNATION"
        );
        if (designation_categories.length > 0)
          designation_category = designation_categories[0];
        this.attendances.push({
          sr_no: sr_no,
          employee_id: user.id,
          employee_code: user.employee_id,
          division_name: user.group_division
            ? user.group_division.division_name
            : "",
          employee_name: user.full_name,
          father_name: user.father_name,
          designation_name: designation_category.category_list
            ? designation_category.category_list.code
            : "",
        });
        sr_no++;
      });
    },

    changeSalaryStatus(user) {
      if (!user.monthly_salary.is_salary_generated) {
        user.monthly_salary.days_absent = moment(
          this.year + "-" + this.month_id,
          "YYYY-M"
        ).daysInMonth();
        user.monthly_salary.ot_hrs = 0;
        user.monthly_salary.reimbursement = 0;
        user.monthly_salary.tds = 0;
        user.monthly_salary.loan = 0;
        user.monthly_salary.salary_advance = 0;
        user.monthly_salary.lic = 0;
        user.monthly_salary.uniform = 0;
        user.monthly_salary.mobile = 0;
        user.monthly_salary.accidental = 0;
        user.monthly_salary.mediclaim = 0;
        user.monthly_salary.shoes = 0;
        user.monthly_salary.id_card = 0;
        user.monthly_salary.penalty = 0;
        user.monthly_salary.others = 0;
        user.monthly_salary.total_deductions = 0;
        user.monthly_salary.total_payable = 0;
        this.updateDays(user, "ABSENT");
      }
    },

    toggleGenerateSalaryForAll() {
      this.getData();
    },
  },
};
</script>

<style scoped>
/* .searchInput {
  width: 180px;
  margin-left: 10px;
} */

/* input {
  width: 40px;
  border: none;
  text-align: right;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.fix {
  position: absolute;
  *position: relative; /*ie7*/
  margin-left: -100px;
  width: 100px;
}

.salary_generated {
  background-color: #d4f8d4 !important;
}

tbody {
  overflow: scroll;
  height: 200px;
}

/* Makes First header Fixed */
thead th:first-child {
  left: 0;
  z-index: 2;
  background: #ddd;
}
/* Makes second header Fixed */
thead th:nth-child(2) {
  left: 50px;
  z-index: 2;
  background: #ddd;
}
/* Makes second header Fixed */
thead th:nth-child(3) {
  left: 114px;
  z-index: 2;
  background: #ddd;
}

/* MAKE LEFT COLUMN FIXEZ */
tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 0;
}
/* Makes Second Column Fixed */
tr > :nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 50px;
}
tr > :nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 114px;
}
</style>
