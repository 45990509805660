<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Attendance
            <add-button
              link="/main/workflows/monitor/attendance/create"
            ></add-button>
            <save-button @click="save"></save-button>
            <upload-button
              link="/main/workflows/monitor/attendance/upload"
            ></upload-button>
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          <small v-if="isSaving">Saving...</small>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Payroll</li>
            <li class="active">Attendance</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Filters -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select MONTH"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select YEAR"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    ></my-select>
                  </div>
                  <div
                    class="col-md-3"
                    v-for="(category, c) in categories"
                    :key="`category${c}`"
                  >
                    <my-select
                      style="margin: 9px !important"
                      @change="searchData"
                      :selection.sync="category.selectedValue"
                      :suggestions="category.active_category_lists"
                      :placeholder="`Select ${category.category_name}`"
                      multiple="true"
                    >
                    </my-select>
                  </div>
                </div>
                <div class="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / Filters -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="row" style="margin-bottom: 5px">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <download-excel
                  :title="title"
                  class="btn btn-default pull-right"
                  :data="attendances"
                  :fields="excel_fields"
                  worksheet="Attendances"
                  name="Attendance.xls"
                >
                  <a href="#" class="download">Export to Excel</a>
                </download-excel>
              </div>
            </div>
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="outer">
                    <div class="inner" style="height: 600px">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th style="z-index: 2">Sr. No.</th>
                            <th>Emp Code</th>
                            <th>Name</th>
                            <th>Photo</th>
                            <th v-for="c in totalDaysInMonth" :key="`days${c}`">
                              {{ c }}
                            </th>
                            <th>Total Payable Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          <loading-table
                            :isLoading="isLoading"
                            cols="34"
                          ></loading-table>
                          <tr v-for="(user, us) in users" :key="`user${us}`">
                            <td class="first-col">
                              {{ us + 1 }}
                            </td>
                            <td class="second-col">{{ user.emp_code }}</td>
                            <td class="third-col">{{ user.full_name }}</td>
                            <td class="third-col">
                              <img
                                :src="`${mediaUrl}${user.image_path}`"
                                alt=""
                                width="50"
                              />
                            </td>
                            <td v-for="c in totalDaysInMonth" :key="`days${c}`">
                              <a
                                class="redUser"
                                id="show-modal"
                                @click="openModal(user[`date${c}`], user.id)"
                              >
                                <i
                                  v-if="user[c] == 'A'"
                                  class="fa fa-user fa-lg"
                                  aria-hidden="true"
                                ></i>
                              </a>

                              <a class="greenUser">
                                <i
                                  v-if="user[c] == 'P'"
                                  class="fa fa-user fa-lg"
                                  aria-hidden="true"
                                ></i
                              ></a>

                              <a class="blueUser" v-if="user[c] == 'R'">
                                <i
                                  class="fa fa-user fa-lg"
                                  aria-hidden="true"
                                ></i
                              ></a>
                            </td>
                            <td>{{ user.payable_days }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
    <transition name="modal" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header"> Add Reliever </slot>
            </div>

            <div class="modal-body">
              <div class="form-wrap form-horizontal">
                <div class="form-group">
                  <label class="col-sm-3 control-label">Select User*</label>
                  <div class="col-sm-9">
                    <my-select
                      :selection.sync="form.reliever_id"
                      :suggestions="Relieverusers"
                      :placeholder="`Select user`"
                    ></my-select>
                    <span class="error-block">{{ errors.reliever_id }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 control-label">Description</label>
                  <div class="col-sm-9">
                    <textarea
                      v-uppercase
                      type="text"
                      class="form-control"
                      placeholder="Description"
                      v-model="form.description"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group mb-0 row">
                  <div class="col-sm-offset-3 col-sm-2">
                    <submit-button
                      :isLoading="isLoading"
                      @submit="addReliever"
                      text="Save"
                    ></submit-button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      @click="showModal = false"
                      style="color: black"
                      class="btn btn-dark"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import moment from "moment";

export default {
  name: "MonthlyAttendance",
  data() {
    return {
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      categories: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      title: "",
      excel_fields: {
        "Sr No": "sr_no",
        "Emp Code": "employee_code",
        "Employee Name": "employee_name",
        "TOTAL PAYABLE DAYS": "total_payable_days",
      },
      attendances: [],
      totalDaysInMonth: "",
      showModal: false,
      Relieverusers: [],
      form: {
        reliever_id: "",
        description: "",
      },
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    this.month_id = moment().format("M");
    this.month_name = moment().format("MMMM");
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
      this.searchData();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      masters.users.forEach((user) => {
        this.Relieverusers.push({
          id: user.id + " - " + user.sites[0].id,
          name: user.full_name,
        });
      });
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
      // this.searchData()
    },
    selectCategory() {
      this.isGenerateSalaryForAll = false;
    },

    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.categories.forEach((category) => {
        category.selectedValue = "";
      });
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData() {
      let selectedCategoriesArray = [];
      this.categories.forEach((category) => {
        selectedCategoriesArray.push(
          category.id + "-" + category.selectedValue
        );
      });
      this.getData(selectedCategoriesArray);
    },
    async getData(selectedCategoriesArray = []) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&selectedCategoriesArray=${selectedCategoriesArray}&searchText=${this.searchText}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = users.data.data;
        console.log(this.users);
        this.exportToExcel();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async save() {
      this.isSaving = true;
      let monthly_salaries = [];
      this.users.forEach((user) => {
        monthly_salaries.push(user.monthly_salary);
      });
      let payload = {
        monthly_salaries: monthly_salaries,
      };
      await axios.post(`/monthly_salaries/multiple`, payload);
      this.isSaving = false;
    },

    exportToExcel() {
      this.title =
        "Attendance Register For the Month of " +
        this.month_name +
        " " +
        this.year;

      this.attendances = [];
      let days = this.totalDaysInMonth;
      for (let index = 1; index <= days; index++) {
        let day = index;
        this.excel_fields["D" + day] = "D" + day;
      }
      let sr_no = 1;

      this.users.forEach((user) => {
        let attendance = {
          sr_no: sr_no,
          employee_code: user.emp_code,
          employee_name: user.full_name,
        };
        for (let index = 1; index <= days; index++) {
          attendance["D" + index] = user[index];
          attendance["total_payable_days"] = user.payable_days;
        }
        this.attendances.push(attendance);
        sr_no++;
      });
    },

    changeSalaryStatus(user) {
      if (!user.monthly_salary.is_salary_generated) {
        user.monthly_salary.days_absent = moment(
          this.year + "-" + this.month_id,
          "YYYY-M"
        ).daysInMonth();
        user.monthly_salary.ot_hrs = 0;
        user.monthly_salary.reimbursement = 0;
        user.monthly_salary.tds = 0;
        user.monthly_salary.loan = 0;
        user.monthly_salary.salary_advance = 0;
        user.monthly_salary.lic = 0;
        user.monthly_salary.uniform = 0;
        user.monthly_salary.mobile = 0;
        user.monthly_salary.accidental = 0;
        user.monthly_salary.mediclaim = 0;
        user.monthly_salary.shoes = 0;
        user.monthly_salary.id_card = 0;
        user.monthly_salary.penalty = 0;
        user.monthly_salary.others = 0;
        user.monthly_salary.total_deductions = 0;
        user.monthly_salary.total_payable = 0;
        this.updateDays(user, "ABSENT");
      }
    },

    toggleGenerateSalaryForAll() {
      this.getData();
    },
    openModal(user, userId) {
      this.reliever_date = user;
      this.userID = userId;
      this.showModal = true;
    },
    async addReliever() {
      try {
        console.log(this.userID);
        this.isLoading = true;
        this.form.date = this.reliever_date;
        this.form.user_id = this.userID;

        await axios.post("/user_attendance_relievers", this.form);
        this.getData();
        this.isLoading = false;
        this.showModal = false;
      } catch (e) {
        this.isLoading = false;
      }
      this.form = {
        reliever_id: "",
      };
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.fix {
  position: absolute;
  *position: relative; /*ie7*/
  margin-left: -100px;
  width: 100px;
}

.salary_generated {
  background-color: #d4f8d4 !important;
}

tbody {
  overflow: scroll;
  height: 200px;
}

/* Makes First header Fixed */
thead th:first-child {
  left: 0;
  z-index: 2;
  background: #ddd;
}
/* Makes second header Fixed */
thead th:nth-child(2) {
  left: 50px;
  z-index: 2;
  background: #ddd;
}
/* Makes second header Fixed */
thead th:nth-child(3) {
  left: 114px;
  z-index: 2;
  background: #ddd;
}

/* MAKE LEFT COLUMN FIXEZ */
tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 0;
}
/* Makes Second Column Fixed */
tr > :nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 50px;
}
tr > :nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 114px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 1000px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.redUser {
  color: red;
}
.greenUser {
  color: green;
}
.blueUser {
  color: blue;
}
a,
a :hover {
  color: black;
  text-decoration: none;
}
</style>
