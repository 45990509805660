<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            PT Register
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-datepicker
                      :date.sync="from_date"
                      placeholder="From Date"
                    ></my-datepicker>
                  </div>
                  <div class="col-md-3">
                    <my-datepicker
                      :date.sync="to_date"
                      placeholder="To Date"
                    ></my-datepicker>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="state_id"
                      :suggestions="states"
                      :placeholder="`Select State`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showPTRegisterTable"></div>
                <section id="mydiv" v-if="showPTRegister">
                  <div v-if="pt_calculations.users.length">
                    <div class="container-fluid">
                      <div class="col-md-12">
                        <p style="font-weight: bold">
                          <center>V ALLIANCE</center>
                        </p>
                        <p style="font-weight: bold">
                          <center>FORM III ( See Rule 12 )</center>
                        </p>
                        <br />

                        <div class="row">
                          <div class="col-md-6">
                            <p>
                              P.Tax Registration Certificate No. : 27801522796P
                            </p>
                            <p>Enrollment Certificate No. :</p>
                          </div>

                          <div class="col-md-6">
                            <p>
                              For Period : {{ pt_calculations.For_Period }}
                              {{ year }}
                            </p>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <table
                            id="table-example-1"
                            class="table table-responsive"
                          >
                            <thead>
                              <tr>
                                <th>
                                  Salary and wage earners. Such persons whose
                                  monthly salaries or wage are -
                                </th>
                                <th>No. of EmployeeS</th>
                                <th>Rate of Tax ( Rs. )</th>
                                <th>Amount of Tax Deducted</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>From 0 to 7500</td>
                                <td>{{ this.slab_01 }}</td>
                                <td>{{ this.tax_deduction_1 }}</td>
                                <td>{{ this.total_tax_deduction_1 }}</td>
                              </tr>
                              <tr>
                                <td>
                                  Exceed 7500 but not Exceed 10000 - female
                                </td>
                                <td>{{ this.slab_02 }}</td>
                                <td>{{ this.tax_deduction_2 }}</td>
                                <td>{{ this.total_tax_deduction_2 }}</td>
                              </tr>
                              <tr>
                                <td>From 7501 to 10000</td>
                                <td>{{ this.slab_03 }}</td>
                                <td>{{ this.tax_deduction_3 }}</td>
                                <td>{{ this.total_tax_deduction_3 }}</td>
                              </tr>
                              <tr>
                                <td>From 10001 to 9999999</td>
                                <td>{{ this.slab_04 }}</td>
                                <td>{{ this.tax_deduction_4 }}</td>
                                <td>{{ this.total_tax_deduction_4 }}</td>
                              </tr>
                              <tr>
                                <td colspan="3" style="font-weight: bold">
                                  Total Amount
                                </td>
                                <td>{{ this.TotalTax }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />

                        <div class="row">
                          <p>
                            The above statements are true or best of my
                            knowledge and belief.
                          </p>
                          <br />
                          <div class="col-md-6">
                            <p>
                              Date :
                              {{
                                new Date()
                                  .toJSON()
                                  .slice(0, 10)
                                  .replace(/-/g, "/")
                              }}
                            </p>
                            <p>Place : MUMBAI</p>
                          </div>

                          <div class="col-md-6">
                            <p>Signature</p>
                            <p>Designation</p>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <hr />

                          <p style="font-size: 20px; font-weight: bold">
                            V ALLIANCE
                          </p>
                          <p>{{ this.pt_calculations.site_address }}</p>
                          <p>Ph. No. {{ this.pt_calculations.site_phone }}</p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Register Not yet Generated</h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFPTRegister",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      states: [],
      state_id: "",
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showPTRegisterTable: false,
      showPTRegister: false,
      from_date: "",
      to_date: "",
    };
  },
  mounted() {
    this.getMonths();
    this.getMonths();
    this.getMasters();

    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      let states = masters.states;
      states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.code,
        });
      });
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showPTRegister = false;
      this.showPTRegisterTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let from_Date = moment(String(this.from_date)).format("YYYY-MM-DD");
        let to_Date = moment(String(this.to_date)).format("YYYY-MM-DD");
        let pt_calculations = await axios.get(
          `/pt_reports/generate_pt?from_date=${from_Date}&to_date=${to_Date}&groupId=${this.group}&groupDivisionId=${this.groupDivision}&stateId=${this.state_id}`
        );
        this.pt_calculations = pt_calculations.data.data;
        this.users = pt_calculations.data.data.users;
        this.pt_calculation = pt_calculations.data.data.pt_configuration;
        this.slab_01 = 0; //earned pt wages from 0 to  7500
        this.slab_02 = 0; //earned pt wages from 7501 to 10000 for Females
        this.slab_03 = 0; //earned pt wages From 7501 to 10000
        this.slab_04 = 0; //earned pt wages From 10001 to 999999
        this.users.forEach((user) => {
          let gender = user.gender;
          // Employee PT
          let total_earnings = user.monthly_salary.total_earnings;
          if (user.is_pt_applicable == 1) {
            let earned_pt_wages = Math.round(total_earnings);
            this.pt_calculation.forEach((pt_config) => {
              if (
                (earned_pt_wages > pt_config.min_wage &&
                  earned_pt_wages < pt_config.max_wage) ||
                (earned_pt_wages > pt_config.min_wage &&
                  pt_config.max_wage == 0)
              ) {
                // earned pt Wages should be in the range of Min to Max wages
                let slab_id = pt_config.id;
                this.tax_deduction_1 = 0;
                this.tax_deduction_2 = 0;
                if (slab_id == 1) {
                  this.slab_01++;
                  this.tax_deduction_1 = pt_config.employee_value;
                } else if (slab_id == 2 || slab_id == 5) {
                  if (gender == 0 && pt_config.is_men == 1) {
                    // If wages is between 7501 to 10000 And Gender is Male
                    this.slab_03++;
                    this.tax_deduction_3 = pt_config.employee_value;
                  }
                  if (gender == 1 && pt_config.is_women == 1) {
                    // If wages is between 7501 to 10000 But Gender is Female
                    this.slab_02++;
                    this.tax_deduction_2 = pt_config.employee_value;
                  }
                }
                // if (earned_pt_wages >"10001") {
                if (slab_id == 3) {
                  // If wages is more than 10001
                  this.slab_04++;
                  this.tax_deduction_4 = pt_config.employee_value;
                }
                // console.log(this.tax_deduction_3);
                this.total_tax_deduction_1 =
                  this.slab_01 * this.tax_deduction_1;
                this.total_tax_deduction_2 =
                  this.slab_02 * this.tax_deduction_2;
                this.total_tax_deduction_3 =
                  this.slab_03 * this.tax_deduction_3;
                this.total_tax_deduction_4 =
                  this.slab_04 * this.tax_deduction_4;
                this.TotalTax =
                  this.total_tax_deduction_1 +
                  this.total_tax_deduction_2 +
                  this.total_tax_deduction_3 +
                  this.total_tax_deduction_4;

                // console.log(this.tax_deduction_1);
              }
            });
          }
        });
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showPTRegisterTable = true;
        this.showPTRegister = false;
      }
      if (source == "generate") {
        this.showPTRegisterTable = false;
        this.showPTRegister = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PTRegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
