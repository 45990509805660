<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            PF Challan
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          &nbsp; &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="btn btn-primary"
                      @click="searchData('search')"
                    >
                      Search</button
                    >&nbsp;&nbsp;
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Title -->

    <!-- Row -->
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default card-view">
          <div class="panel-wrapper collapse in">
            <div class="panel-body">
              <errors :data="errors" />

              <div class="table-wrap" v-if="showChallanTable">
                <div class="inner" style="height: 700px">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th style="z-index: 2">Sr. No.</th>
                        <th>Emp Code</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="34"
                      ></loading-table>
                      <tr v-for="(user, us) in users" :key="`user${us}`">
                        <td class="first-col">
                          {{ us + 1 }}
                        </td>
                        <td class="second-col">{{ user.emp_code }}</td>
                        <td class="third-col">{{ user.full_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <section id="mydiv" v-if="showChallan">
                <div
                  class="pageBorder"
                  v-if="monthly_salary_pf.register.length"
                >
                  <div class="container-fluid">
                    <div class="row heading-bg">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <h5 class="txt-dark">
                          <center>
                            <p>COMBINED CHALLAN - A/C NO.1, 2, 10, 21 & 22</p>
                          </center>
                          <center><p>STATE BANK OF INDIA</p></center>
                          <h5>EMPLOYEE'S PROVIDENT FUND ORGANIZATION</h5>
                          <center>
                            <p>(USE SEPERATE CHALLAN FOR EACH MONTH)</p>
                          </center>
                        </h5>
                      </div>
                      <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                        <p>Orignal</p>
                        <p>Triplicate</p>
                      </div>
                      <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                        <p>Duplicate</p>
                        <p>Quadruplicate</p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="container-fluid row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>
                        ESTABLISHMENT CODE NO.:
                        {{ monthly_salary_pf.site_establishment_code }}
                      </p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>ACCOUNT GROUP NO:-</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>
                        PAID BY CHEQUE/CASH :-{{
                          monthly_salary_pf.payment_mode
                        }}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>DUES FOR THE MONTH OF:</p>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>Employees Share : {{ month_name }} {{ year }}</p>
                      <p>Employer Share : {{ month_name }} {{ year }}</p>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12"></div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>DATE OF PAYMENT: {{ date_of_payment }}</p>
                    </div>
                  </div>
                  <hr />
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <p>Total No. Of Subscribers:</p>
                      <br />
                      <p>Total Wages Due:</p>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="monthly_salary_pf.total_epf_users"
                      />
                      <p>A/C No. 1</p>
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="monthly_salary_pf.total_epf"
                      />
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="monthly_salary_pf.total_eps_users"
                      />
                      <p>A/C No. 10</p>
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="monthly_salary_pf.total_eps"
                      />
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="monthly_salary_pf.total_epf_users"
                      />
                      <p>A/C No. 21</p>
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="monthly_salary_pf.total_epf"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="container-fluid">
                    <table class="table table-responsive col-md-10">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>No. PARTICULARS</th>
                          <th>A/C NO 1</th>
                          <th>A/C NO 2</th>
                          <th>A/C NO 10</th>
                          <th>A/C NO 21</th>
                          <th>A/C NO 22</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>PART NO 1</td>
                          <td></td>
                          <td></td>
                          <td colspan="4">---AMOUNT IN (RUPEES)---</td>
                        </tr>
                        <tr>
                          <td>1.</td>
                          <td>EMPLOYER'S SHARES OF CONT.</td>
                          <td>{{ monthly_salary_pf.employer_epf }}</td>
                          <td></td>
                          <td>{{ monthly_salary_pf.employer_eps }}</td>
                          <td>{{ monthly_salary_pf.employer_edli }}</td>
                          <td></td>
                          <td>
                            {{ monthly_salary_pf.total_employer_share }}
                          </td>
                        </tr>
                        <tr>
                          <td>2.</td>
                          <td>EMPLOYEE'S SHARES OF CONT.</td>
                          <td>{{ monthly_salary_pf.employee_epf }}</td>
                          <td></td>
                          <td>0.00</td>
                          <td></td>
                          <td></td>
                          <td>
                            {{ monthly_salary_pf.total_employee_share }}
                          </td>
                        </tr>
                        <tr>
                          <td>3.</td>
                          <td>ADM. CHARGES</td>
                          <td></td>
                          <td>{{ monthly_salary_pf.epf_admin_charge }}</td>
                          <td></td>
                          <td></td>
                          <td>0.00</td>
                          <td>
                            {{ monthly_salary_pf.total_admin_charge }}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>TOTAL</td>
                          <td>{{ monthly_salary_pf.total_of_acc_1 }}</td>
                          <td>{{ monthly_salary_pf.total_of_acc_2 }}</td>
                          <td>{{ monthly_salary_pf.total_of_acc_10 }}</td>
                          <td>{{ monthly_salary_pf.total_of_acc_21 }}</td>
                          <td>{{ monthly_salary_pf.total_of_acc_22 }}</td>
                          <td>{{ monthly_salary_pf.all_total }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <center>
                      <p>
                        (Amount in words
                        {{ monthly_salary_pf.all_total_in_words }})
                      </p>
                    </center>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>NAME OF ESTABLISHMENT:</p>
                      <br />
                      <p>Address</p>
                      <br />
                      <br />
                      <br />
                      <p>NAME OF THE DEPOSITOR</p>
                      <p>SIGNATURE OF THE DEPOSITOR</p>
                      <br />
                      <br />
                      <p>Name Of The Bank: ICICI Bank</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p style="font-size: 20px; font-weight: bold">
                        V ALLIANCE
                      </p>
                      <br />
                      <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
                      <p>CBD Belapur, Navi Mumbai-4000614.</p>
                      <p>Plot no-61</p>
                      <p>Navi Mumbai-4000614.</p>
                      <br />
                      <br />
                      <p style="font-size: 18px; font-weight: bold">
                        (TO BE FILLED BY EMPLOYER)
                      </p>
                      <p>CHEQUE NO</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <p>(For Bank Use Only)</p>
                      <p>Amount Received Rs:</p>
                      <p>For Cheque Only:</p>
                      <p>Date Of Peresentation:</p>
                      <br />
                      <br />
                      <p>Date Of Realization:</p>
                      <p>Branch Name:</p>
                      <p>DATE: 12/04/2021</p>
                    </div>
                  </div>
                  <hr />
                  <div class="container-fluid">
                    <center>
                      <p style="font-size: 20px; font-weight: bold">
                        FOR USE IN REPECT OF PAST ACCUMULATIONS ONLY
                      </p>
                    </center>
                    <table class="table table-responsive col-md-10">
                      <thead>
                        <tr>
                          <th>S. No. PARTICULARS</th>
                          <th>A/C NO 1</th>
                          <th>A/C NO 2</th>
                          <th>A/C NO 10</th>
                          <th>A/C NO 21</th>
                          <th>A/C NO 22</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>PART NO 2</td>
                          <td></td>
                          <td></td>
                          <td colspan="4">---AMOUNT IN (RUPEES)---</td>
                        </tr>
                        <tr>
                          <td>1 EER'S SHARE OF PAST ACCUM.</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>2. EES' SHARE OF PAST ACCUM.</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3. INT. ON SECURITES</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>TOTAL</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-else>
                  <h5>Salary Not Generated Yet !!!</h5>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFChallan",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      monthly_salary_pf: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showChallanTable: false,
      showChallan: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showChallan = false;
      this.showChallanTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        this.monthly_salary_pf = [];
        let monthly_salary_pf = await axios.get(
          `/pf_register/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.monthly_salary_pf = monthly_salary_pf.data.data;
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        // console.log(this.monthly_salary_pf.data.register);
        // this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
          // console.log(this.user)
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showChallanTable = true;
        this.showChallan = false;
      }
      if (source == "generate") {
        this.showChallanTable = false;
        this.showChallan = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PFChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
<style>
#mydiv {
  margin: 20px;
}
</style>
