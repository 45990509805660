<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Form 3A</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>PF reports</li>
            <li class="active">Form 3A</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-9">
                      <autocomplete
                        :search="search"
                        placeholder="Search employee"
                        aria-label="Search employee"
                        :get-result-value="getResultValue"
                        @submit="handleSubmit"
                      ></autocomplete>
                    </div>
                  </div>
                  <br />
                  <u>{{ form.full_name }}</u>
                  <div class="row">
                    <div class="col-md-3">
                      <my-select
                        :selection.sync="from_year"
                        :suggestions="years"
                        placeholder="Select From Year"
                      ></my-select>
                    </div>
                    <div class="col-md-3">
                      <my-select
                        :selection.sync="to_year"
                        :suggestions="years"
                        placeholder="Select To Year"
                      ></my-select>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-success" @click="generatePDF">
                        Save & Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <!-- <errors :data="errors" /> -->

                <!-- <div class="table-wrap" v-if="showForm3aTable"></div> -->
                <div v-if="response.month">
                  <!-- {{ response }} -->
                  <section id="mydiv">
                    <div class="container-fluid">
                      <div class="row heading-bg">
                        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                          <p>(For unexempted establishments Only)</p>
                        </div>
                        <div class="col-lg-9 col-md-4 col-sm-4 col-xs-12">
                          <h5 class="txt-dark">
                            <center>
                              <p style="font-weight: bold; font-size: 18px">
                                Form 3A (Revised)
                              </p>
                            </center>
                            <br />
                            <p>
                              The Employee's Provident Fund Scheme, 1952 (Para
                              35 & 42) and The Employee's Pension Scheme, 1955
                              (Para 19)
                            </p>
                            <p>
                              Contribution Card for Currency Period From 1st
                              April 2020 to 31st March 2021
                            </p>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="container-fluid row">
                      <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
                        <p>
                          1. P.F. ACCOUNT No.:
                          {{ response.pf_no ? response.pf_no : "" }}
                        </p>
                        <p>
                          2. UAN No:
                          {{ response.uan_no ? response.uan_no : "" }}
                        </p>
                        <p>
                          3. Name/ Surname :
                          {{ response.full_name ? response.full_name : "" }}
                        </p>
                        <p>(In Block Letters)</p>
                        <p>
                          5. Father's / Husband Name:
                          {{ response.father_name ? response.father_name : "" }}
                        </p>
                        <p>(In Block Letters)</p>
                        <p>6. Statutory rate of P.F. Contribution 12.00 %</p>
                        <p>
                          7. Voluntary higher rate of employee's Contribution if
                          any NIL %
                        </p>
                      </div>
                      <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
                        <p>4. Name & Address of the Factory / Establishment</p>
                        <p style="font-size: 20px; font-weight: bold">
                          {{
                            response.sites[0].name ? response.sites[0].name : ""
                          }}
                        </p>
                        <p>
                          {{
                            response.sites[0].address
                              ? response.sites[0].address
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div class="container-fluid">
                      <p style="font-size: 18px; font-weight: bold">
                        CONTRIBUTION
                      </p>
                      <table
                        id="table-example-1"
                        class="table table-responsive col-md-10"
                      >
                        <thead>
                          <tr>
                            <th rowspan="2">Month</th>
                            <th rowspan="2">Amount Of Wages</th>
                            <th rowspan="2">Worker's Share E.P.F</th>
                            <th colspan="2">
                              <center>Employee's Share</center>
                            </th>
                            <th rowspan="2">Refund Of Advances</th>
                            <th rowspan="2">
                              No. Of days/period of non-contributig service (if
                              any)
                            </th>
                            <th rowspan="2">Remark</th>
                          </tr>
                          <tr>
                            <th>
                              E.P.F difference between 12% and 8.33% (if any)
                            </th>
                            <th>pension fund contribution 8.33%</th>
                          </tr>
                          <tr>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4(a)</th>
                            <th>4(b)</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>March Paid</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td rowspan="5">
                              (a) Date Of Leaving Service (if any)
                            </td>
                          </tr>
                          <tr>
                            <td>In April</td>
                            <td>{{ response.month[0].amount_of_wages }}</td>
                            <td>{{ response.month[0].worker_share }}</td>
                            <td>{{ response.month[0].EPF_4A }}</td>
                            <td>{{ response.month[0].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[0].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>May</td>
                            <td>{{ response.month[1].amount_of_wages }}</td>
                            <td>{{ response.month[1].worker_share }}</td>
                            <td>{{ response.month[1].EPF_4A }}</td>
                            <td>{{ response.month[1].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[1].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>June</td>
                            <td>{{ response.month[2].amount_of_wages }}</td>
                            <td>{{ response.month[2].worker_share }}</td>
                            <td>{{ response.month[2].EPF_4A }}</td>
                            <td>{{ response.month[2].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[2].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>July</td>
                            <td>{{ response.month[3].amount_of_wages }}</td>
                            <td>{{ response.month[3].worker_share }}</td>
                            <td>{{ response.month[3].EPF_4A }}</td>
                            <td>{{ response.month[3].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[3].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>August</td>
                            <td>{{ response.month[4].amount_of_wages }}</td>
                            <td>{{ response.month[4].worker_share }}</td>
                            <td>{{ response.month[4].EPF_4A }}</td>
                            <td>{{ response.month[4].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[4].days_absent }}</td>
                            <td rowspan="8">
                              (b) Reasons for leaving service (if any)
                            </td>
                          </tr>
                          <tr>
                            <td>September</td>
                            <td>{{ response.month[5].amount_of_wages }}</td>
                            <td>{{ response.month[5].worker_share }}</td>
                            <td>{{ response.month[5].EPF_4A }}</td>
                            <td>{{ response.month[5].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[5].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>October</td>
                            <td>{{ response.month[6].amount_of_wages }}</td>
                            <td>{{ response.month[6].worker_share }}</td>
                            <td>{{ response.month[6].EPF_4A }}</td>
                            <td>{{ response.month[6].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[6].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>November</td>
                            <td>{{ response.month[7].amount_of_wages }}</td>
                            <td>{{ response.month[7].worker_share }}</td>
                            <td>{{ response.month[7].EPF_4A }}</td>
                            <td>{{ response.month[7].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[7].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>December</td>
                            <td>{{ response.month[8].amount_of_wages }}</td>
                            <td>{{ response.month[8].worker_share }}</td>
                            <td>{{ response.month[8].EPF_4A }}</td>
                            <td>{{ response.month[8].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[8].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>January</td>
                            <td>{{ response.month[9].amount_of_wages }}</td>
                            <td>{{ response.month[9].worker_share }}</td>
                            <td>{{ response.month[9].EPF_4A }}</td>
                            <td>{{ response.month[9].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[9].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>Febuary</td>
                            <td>{{ response.month[10].amount_of_wages }}</td>
                            <td>{{ response.month[10].worker_share }}</td>
                            <td>{{ response.month[10].EPF_4A }}</td>
                            <td>{{ response.month[10].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[10].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>Febuary Paid</td>
                            <td>{{ response.month[11].amount_of_wages }}</td>
                            <td>{{ response.month[11].worker_share }}</td>
                            <td>{{ response.month[11].EPF_4A }}</td>
                            <td>{{ response.month[11].EPF_4B }}</td>
                            <td></td>
                            <td>{{ response.month[11].days_absent }}</td>
                          </tr>
                          <tr>
                            <td>In March</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td>{{ total.total_amount_of_wages }}</td>
                            <td>{{ total.total_worker_share }}</td>
                            <td>{{ total.total_EPF_4A }}</td>
                            <td>{{ total.total_EPF_4B }}</td>
                            <td></td>
                            <td>{{ total.total_Absent }}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="container-fluid row">
                      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
                        <p>
                          Certified that the total of contribution (both shares)
                          included in this Card i.e Rs 21691.00 has already been
                        </p>
                        <p>
                          remitted in full in EPF A/c No. 1 and Pension Fund A/c
                          No. 1011527.00 (vide note below)
                        </p>
                        <p>
                          Certified that the difference between the total of the
                          contribution shown under columns 3,4(a) & 4(b) of the
                          above table and that arrived at on
                        </p>
                        <p>
                          the total wages shown in column 2 at the prescribed
                          rate is solely due to rounding off of contributions to
                          the nearest rupee under the rules.
                        </p>
                        <p>Date 01/06/2021</p>
                        <hr />
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12"></div>
                      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
                        <p>
                          Note: (1) In respect of the form 3A sent to the
                          Regional Office during the course of the currency
                          period for the purpose of final settelment of the
                        </p>
                        <p>
                          &nbsp;accounts of the memner, who has left service,
                          details of date & reasons for leaving service should
                          be furnished under columns, 7(a) & (b)
                        </p>
                        <p>
                          (2) In respect of those who are members of the Pension
                          Fund, the employers share of contribution to the EPF
                          will be 8.1/3% or 12%
                        </p>
                        <p>
                          as the case may be & is to be shown under column 4(a)
                        </p>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-12"
                        style="
                          border-style: solid;
                          border-width: thin;
                          border-color: #111111;
                          margin-top: -120px;
                        "
                      >
                        <p style="font-size: 20px; font-weight: bold">
                          for V ALLIANCE
                        </p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <p>Authorised signature</p>
                        Signature of the Employer with Office Seal
                      </div>
                    </div>
                  </section>
                </div>
                <div v-else>
                  <h5>Form 3A Not Generated!!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import axios from "axios";
import store from "@/store";
import { updateFormData } from "@/static/helpers.js";

export default {
  name: "PDFForm3A",
  data() {
    return {
      form: {},
      isLoading: false,
      addressTypes: [],
      response: [],
      configurations: [],
      showForm3a: false,
      to_year: "",
      from_year: "",
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;

      masters.address_types.forEach((addressType) => {
        this.addressTypes.push({
          id: addressType.id,
          name: addressType.code,
          code: addressType.code,
        });
      });

      this.configurations = masters.configurations;

      // Get Employee
      if (this.employee != null) {
        this.updateForm(this.employee);
      }
    },
    async search(input) {
      let response = await axios.get(`/pf_register/Form3A?search=${input}`);
      // console.log(response.data.data.register);
      return response.data.data.register;
      // }
    },
    getResultValue(result) {
      // console.log(result.full_name);
      return result.full_name;
    },
    async handleSubmit(result) {
      this.response = [];
      // console.log(result.id);

      let response = await axios.get(
        `pf_register/Form3A/${result.id}&from_year=${this.from_year}&to_year=${this.to_year}`
      );
      store.dispatch("employee/setEmployee", response.data.data);
      this.response = response.data.data;
      this.total = response.data;
      // console.log(this.response);
      this.updateForm(response.data);
    },
    updateForm(formData) {
      this.form = updateFormData(formData, this.addressTypes, this.site);
    },
    async update() {
      try {
        this.isLoading = true;
        let response = await axios.post(`/users`, this.form);
        store.dispatch("employee/setEmployee", response.data.data);
        this.updateForm(response.data.data);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PFForm3A.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
