<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            LWF Challan
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="state_id"
                      :suggestions="states"
                      :placeholder="`Select State`"
                    >
                    </my-select>
                  </div>

                  <div class="col-md-3">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />
                <div class="table-wrap" v-if="showLWFChallanTable"></div>
                <section id="mydiv" v-if="showLWFChallan">
                  <div v-if="lwf_configurations.total_users != 0">
                    <div class="container-fluid">
                      <div class="col-md-12">
                        <p style="font-weight: bold">
                          <center>Maharashtra Labour Welfare Board</center>
                        </p>
                        <p>
                          <center>
                            Hutatma Bau Genu, Mumbai Kamgar Kreeda Bhavan,
                            Senapati Bapat Marg, Elphinstone, Mumbai - 400 013
                          </center>
                        </p>
                        <p style="font-weight: bold">
                          <center>FORM A-1-CUM RETURN</center>
                          <center>(VIDE RULE 3-A)</center>
                        </p>
                        <br />

                        <div class="row">
                          <div class="col-md-9">
                            <p>
                              <span style="font-weight: bold">Note: </span>1)
                              This form cum - return is required to be submitted
                              by every Employer alongwith the of Employee's six
                              monthly contribution made by him in respect of all
                              employee's whose names stand on the register of
                              his establishment as c{{
                                lwf_configurations.days
                              }}
                              {{ month_name }} {{ year }}
                            </p>
                            <p>
                              as per the provisions of Section 6BB of the Bombay
                              Labour Welfare Fund Act, 1953
                            </p>
                            <p>
                              2) Section 2 (2)(b) of Bombay Welfare Fund Act,
                              1953 "Supervisor" means who, being emplpoyed in a
                              supervisory capacity, draws wages esceeding Three
                              Thousand Five Hundred rupees per mensum or
                              exercise, either by the nature of the duties
                              attached to the office, or by reason of the powers
                              vested in him, functions mainly of a managerial
                              nature.
                            </p>
                            <p>
                              3) EEC = Employee's contribution, ERC = Employer's
                              contribution.
                            </p>
                          </div>

                          <div class="col-md-3">
                            <p>for office use only</p>
                            <div
                              style="
                                border-style: solid;
                                border-width: thin;
                                border-color: #111111;
                              "
                            >
                              C
                              <hr />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col-md-3">
                            <span style="font-weight: bold"
                              >Establishment Code No.</span
                            >
                          </div>
                          <div class="col-md-6">
                            <div
                              style="
                                border-style: solid;
                                border-width: thin;
                                border-color: #111111;
                              "
                            >
                              {{ lwf_configurations.site.establishment_code }}
                            </div>
                          </div>
                        </div>

                        <ol type="1">
                          <div class="row">
                            <div class="col-md-3">
                              <li>
                                <p>Name & Address of the Establishment</p>
                              </li>
                              <!-- </ol> -->
                            </div>
                            <div class="col-md-9">
                              <p>V ALLIANCE</p>
                              <p>{{ lwf_configurations.site.address }}</p>
                              <p>{{ lwf_configurations.site.phone }}</p>
                            </div>
                          </div>
                          <br />

                          <div class="row">
                            <div class="col-md-3">
                              <li>
                                <p>Name of the employer</p>
                              </li>
                            </div>
                            <div class="col-md-9">
                              <p>Survarna Gaikwad</p>
                            </div>
                          </div>
                          <br />

                          <div class="row">
                            <div class="col-md-3">
                              <li>
                                <p>
                                  Class of the Establishment (i.e whether
                                  factory, motor omnibus service, motor
                                  transport undertaking or commercial
                                  establishment, a a resident hotel, restaurant,
                                  eating house, theatre or other place of
                                  amusement of public entertainment.)
                                </p>
                              </li>
                            </div>
                            <div class="col-md-9"></div>
                          </div>
                          <br />
                        </ol>

                        <div class="row col-md-3">
                          <ol start="4">
                            <li>
                              <p>
                                Total number of employees whose names stood on
                                the establishment register as on 31 December
                                2020
                              </p>
                            </li>
                            <br />

                            <ol type="a">
                              <li>
                                <p>Manager</p>
                              </li>
                              <br />

                              <li>
                                <p>
                                  Employees working in supervisory capacity
                                  drawing wages exceeding Rs. 3500/- per month
                                  (Please refer NOTE 2 above)
                                </p>
                              </li>
                              <br />

                              <li>
                                <p>
                                  Employees drawing wages upto & inclusive of
                                  Rs. 3000/- per month
                                </p>
                                <p>EEC @ Rs. 6/- per employee</p>
                                <p>ERC @ Rs. 18/- per employee</p>
                              </li>
                              <br />

                              <li>
                                <p>
                                  Employees drawing wages exceeding Rs. 3,000/-
                                  per month
                                </p>
                                <p>EEC @ Rs. 12 per employee &</p>
                                <p>ERC @ Rs. 36 per employee</p>
                              </li>
                              <br />
                            </ol>
                            <li>
                              <p>Total of (a) to (d) above</p>
                            </li>
                            <br />

                            <li>
                              <p>Mode of Payment:-</p>
                              <p>
                                (Please specify No. & date of cheque/ draft/
                                money order/cash against this item.)
                              </p>
                            </li>
                            <br />
                          </ol>
                        </div>
                        <br />

                        <!-- <div class="row"> -->
                        <div class="col-md-1">
                          <!-- <br />
          <br />
          <br />
          <br />
          <br />
          <p>June 2019</p>
          <p>
            Dec 2019
          </p>
          <p>
            June
          </p>
          <br />
          <p>
            Dec
          </p> -->
                        </div>
                        <div class="col-md-8">
                          <table
                            id="table-example-1"
                            class="table table-responsive col-md-10"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th>No. of Employee</th>
                                <th>E.E.C Rs</th>
                                <th>E.R.C Rs</th>
                                <th>Pental Interest Rs</th>
                                <th>Total Rs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>0.00</td>
                                <td>NIL</td>
                                <td>NIL</td>
                                <td>NIL</td>
                                <td>NIL</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>0.00</td>
                                <td>NIL</td>
                                <td>NIL</td>
                                <td>NIL</td>
                                <td>NIL</td>
                              </tr>
                              <tr>
                                <td>June</td>
                                <td>0</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>Dec</td>
                                <td>0</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                              </tr>
                              <tr>
                                <td>June {{ year }}</td>
                                <td>{{ lwf_configurations.june_users }}</td>
                                <td>{{ lwf_configurations.june_eec }}.00</td>
                                <td>{{ lwf_configurations.june_erc }}.00</td>
                                <td>0.00</td>
                                <td>{{ lwf_configurations.june_total }}.00</td>
                              </tr>
                              <tr>
                                <td>Dec {{ year }}</td>
                                <td>{{ lwf_configurations.dec_users }}</td>
                                <td>{{ lwf_configurations.dec_eec }}.00</td>
                                <td>{{ lwf_configurations.dec_erc }}.00</td>
                                <td>0.00</td>
                                <td>{{ lwf_configurations.dec_total }}.00</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>{{ lwf_configurations.total_users }}</td>
                                <td>{{ lwf_configurations.total_eec }}.00</td>
                                <td>{{ lwf_configurations.total_erc }}.00</td>
                                <td>0.00</td>
                                <td>{{ lwf_configurations.total_total }}.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- </div> -->
                        <div class="row">
                          <div class="col-md-12">
                            <div class="col-md-6">
                              <p style="font-weight: bold">IMPORTANT</p>
                              <ol type="1">
                                <li>
                                  Cheque / DD should be drawn to each Est. Code,
                                  Number seperately & in favor of Maharashtra
                                  Labour Welfare Fund.
                                </li>
                                <li>
                                  Cash Payment will be accepted from 10:30 a.m
                                  to 3:00 p.m
                                </li>
                                <li>
                                  Code no of the Establishment alloted should be
                                  quated at the appropriate place in this form
                                </li>
                                <li>DD should be payable at BOMBAY only</li>
                                <li>
                                  Please write the Establishment code number on
                                  the back side of the cheque/DD
                                </li>
                              </ol>
                            </div>
                            <div class="col-md-6">
                              <br />
                              <p>
                                Certified that the information / particulars
                                furnished above is / are true to te best of my
                                knowledge and belief.
                              </p>
                              <br />
                              <br />
                              <br />
                              <p>
                                Signature with name & designation of the
                                Authority filing this form-cum-return
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Challan Not yet Generated</h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFLWFChallan",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
        { id: 2024, name: 2024 },
        { id: 2025, name: 2025 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      states: [],
      state_id: "",
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showLWFChallanTable: false,
      showLWFChallan: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      let states = masters.states;
      states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.code,
        });
      });
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showLWFChallan = false;
      this.showLWFChallanTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let lwf_configurations = await axios.get(
          `/lwf_report/generate?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}&stateId=${this.state_id}`
        );
        this.lwf_configurations = lwf_configurations.data.data;
        console.log(lwf_configurations);
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showLWFChallanTable = true;
        this.showLWFChallan = false;
      }
      if (source == "generate") {
        this.showLWFChallanTable = false;
        this.showLWFChallan = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "LWFChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
